import { Formik } from "formik";
import { get } from "lodash";
import React from "react";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { ListButton } from "../../../../ui/components/ListButton";
import { Autocomplete } from "../../../../ui/components/Autocomplete";

export const QuickbooksConfig = ({
  configData = {},
  handleSubmit,
  handleClose,
}) => {
  // eslint-disable-next-line no-unused-vars
  const {
    tax_codes,
    account_names = [],
    class_names = [],
    liability_account_names = [],
    user_config,
  } = configData;

  const initialValues = {
    inspection_tax_code:
      user_config?.inspection_tax_code || get(tax_codes[0], "Name", ""),
    inspection_account_name:
      user_config?.inspection_account_name || get(account_names[0], "Name", ""),
    inspection_misc_tax_code:
      user_config.inspection_misc_tax_code || get(tax_codes[0], "Name", ""),
    inspection_misc_account_name:
      user_config?.inspection_misc_account_name ||
      get(account_names[0], "Name", ""),
    processing_tax_code:
      user_config?.processing_tax_code || get(tax_codes[0], "Name", ""),
    processing_account_name:
      user_config?.processing_account_name || get(account_names[0], "Name", ""),
    processing_misc_tax_code:
      user_config?.processing_misc_tax_code || get(tax_codes[0], "Name", ""),
    processing_misc_account_name:
      user_config?.processing_misc_account_name ||
      get(account_names[0], "Name", ""),
    class_name: user_config?.class_name || get(class_names[0], "Name", ""),
    tax_calculation: user_config?.tax_calculation || "NotApplicable",
    no_gst_tax_code:
      user_config?.no_gst_tax_code || get(tax_codes[0], "Name", ""),
    manufacturer_declaration_account_name:
      user_config?.manufacturer_declaration_account_name ||
      get(liability_account_names[0], "Name", ""),
    manufacturer_declaration_tax_code:
      user_config?.manufacturer_declaration_tax_code ||
      get(tax_codes[0], "Name", ""),
  };

  const taxCalculationOptions = [
    { label: "Not Applicable", value: "NotApplicable" },
    { label: "Tax Excluded", value: "TaxExcluded" },
    { label: "Tax Inclusive", value: "TaxInclusive" },
  ];

  const accountOptions = account_names.map((account) => ({
    label: account["Name"],
    value: account["Name"],
  }));

  const incomeAccountOptions = liability_account_names.map((account) => ({
    label: account["Name"],
    value: account["Name"],
  }));

  const classOptions = class_names.map((classOption) => ({
    label: classOption["Name"],
    value: classOption["Name"],
  }));

  const taxOptions = tax_codes.map((account) => ({
    label: account["Name"],
    value: account["Name"],
  }));

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <div className="p-5">
          <h3 className="mb-10">Configure Integration</h3>
          <MappedSelect
            name="no_gst_tax_code"
            options={taxOptions}
            label="No GST Tax Code"
          />
          <MappedSelect
            name="inspection_tax_code"
            options={taxOptions}
            label="Inspection Tax Code"
          />
          <Autocomplete
            name="inspection_account_name"
            placeholder="Inspection Account Name"
            setValue={setFieldValue}
            defaultValueField="value"
            options={accountOptions}
            className="mb-3"
            defaultValue={values.inspection_account_name}
          />
          <MappedSelect
            name="inspection_misc_tax_code"
            options={taxOptions}
            label="Inspection Misc Tax Code"
          />
          <Autocomplete
            name="inspection_misc_account_name"
            options={accountOptions}
            placeholder="Inspection Misc Account Name"
            className="mb-3"
            setValue={setFieldValue}
            defaultValueField="value"
            defaultValue={values.inspection_misc_account_name}
          />
          <MappedSelect
            name="processing_tax_code"
            options={taxOptions}
            label="Processing Tax Code"
          />
          <Autocomplete
            name="processing_account_name"
            options={accountOptions}
            placeholder="Processing Account Name"
            className="mb-3"
            setValue={setFieldValue}
            defaultValueField="value"
            defaultValue={values.processing_account_name}
          />
          <MappedSelect
            name="processing_misc_tax_code"
            options={taxOptions}
            label="Processing Misc Tax Code"
          />
          <Autocomplete
            name="processing_misc_account_name"
            options={accountOptions}
            placeholder="Processing Misc Account Name"
            className="mb-3"
            setValue={setFieldValue}
            defaultValueField="value"
            defaultValue={values.processing_misc_account_name}
          />
          <MappedSelect
            name="class_name"
            options={classOptions}
            label="Class Name"
            defaultValue={get(classOptions[0], "Name")}
          />
          <MappedSelect
            name="tax_calculation"
            options={taxCalculationOptions}
            label="Tax Calculation"
          />
          <Autocomplete
            name="manufacturer_declaration_account_name"
            placeholder="Manufacturer Declaration Account Name"
            setValue={setFieldValue}
            defaultValueField="value"
            options={incomeAccountOptions}
            className="mb-3"
            defaultValue={values.manufacturer_declaration_account_name}
          />
          <MappedSelect
            name="manufacturer_declaration_tax_code"
            options={taxOptions}
            label="Manufacturer Declaration Tax Code"
          />
          <div className="d-flex justify-content-end mt-20">
            <ListButton
              label="Cancel"
              className="mr-4 px-16"
              size="small"
              variant="outlined"
              boxShadow={false}
              text="#407A28"
              onClick={handleClose}
              disabled={isSubmitting}
            />
            <ListButton
              label="Save"
              size="small"
              className="px-20"
              disabled={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
