import React, { useState } from "react";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getUserGroups, modifyUsersGroups } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { error, info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { MappedCheckbox } from "../../../ui/components/Checkbox";
import { MappedRadio } from "../../../ui/components/Radio";
import { MappedSwitch } from "../../../ui/components/MappedSwitch";
import { AVAILABLE_ROLES } from "../../constants";
import { AccessControl } from "../../../ui/structures/AccessControl";

const useStyles = makeStyles({
  label: {
    color: "black",
    fontSize: "16px",
  },
});

export const UserPermissions = ({
  name,
  userGroups = [],
  groupsList = [],
  id,
  setUserGroups,
}) => {
  const labelStyle = useStyles();

  const globalAdmin = groupsList.find(({ type }) => type === "global_admin");
  const agsafeAdmin = groupsList.find(({ type }) => type === "agsafe_admin");
  const agsafeStaff = groupsList.find(({ type }) => type === "agsafe_staff");
  const caAccess = groupsList.find(({ type }) => type === "ca_access");
  const rcAccess = groupsList.find(({ type }) => type === "rc_access");
  const processor = groupsList.find(({ type }) => type === "processor");
  const manufacturer = groupsList.find(({ type }) => type === "manufacturer");
  const endUser = groupsList.find(({ type }) => type === "end_user");
  const guest = groupsList.find(({ type }) => type === "guest");
  const invoiceApprover = groupsList.find(({ type }) => type === "approver");
  const manufacturerApprover = groupsList.find(
    ({ type }) => type === "man_approver"
  );

  const corePermissions = [
    globalAdmin,
    agsafeAdmin,
    agsafeStaff,
    { id: rcAccess?.id, name: "Regional Consultant" },
    endUser,
    guest,
  ];

  const userGroupsIds = userGroups.map(({ id }) => id);

  const initialValues = {
    core_permission: corePermissions.find((permission) =>
      userGroupsIds.includes(permission?.id)
    )?.id,
    ca_access: userGroupsIds.includes(caAccess?.id),
    processor: userGroupsIds.includes(processor?.id),
    manufacturer: userGroupsIds.includes(manufacturer?.id),
    approver: userGroupsIds.includes(invoiceApprover?.id),
    man_approver: userGroupsIds.includes(manufacturerApprover?.id),
  };

  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const handleReset = (resetForm) => () => {
    resetForm({ values: initialValues });
  };

  const validateManufacturerApprover = (addIDs = []) => {
    const restrictedIds = [
      agsafeStaff?.id,
      caAccess?.id,
      rcAccess?.id,
      processor?.id,
      endUser?.id,
      guest?.id,
    ];
    if (
      addIDs.find(
        (id) =>
          restrictedIds.includes(id) &&
          addIDs.includes(manufacturerApprover?.id)
      )
    ) {
      error("Only admins can be manufacturer approvers!");
      return false;
    } else return true;
  };

  const validateManufacturerEndUser = (addIDs = []) => {
    const restrictedIds = [
      globalAdmin?.id,
      agsafeAdmin?.id,
      agsafeStaff?.id,
      caAccess?.id,
      rcAccess?.id,
      processor?.id,
      guest?.id,
    ];
    if (
      addIDs.find(
        (id) => restrictedIds.includes(id) && addIDs.includes(manufacturer?.id)
      )
    ) {
      error("Only End User can be manufacturer!");
      return false;
    } else return true;
  };

  const validateApprover = (addIDs = []) => {
    const restrictedIds = [
      agsafeStaff?.id,
      caAccess?.id,
      rcAccess?.id,
      processor?.id,
      manufacturer?.id,
      endUser?.id,
      guest?.id,
    ];
    if (
      addIDs.find(
        (id) =>
          restrictedIds.includes(id) && addIDs.includes(invoiceApprover?.id)
      )
    ) {
      error("Only admins can be invoice approvers!");
      return false;
    } else return true;
  };

  const validateGuest = (addIDs = []) => {
    const restrictedIds = [
      caAccess?.id,
      processor?.id,
      invoiceApprover?.id,
      manufacturer?.id,
      manufacturerApprover?.id,
    ];
    if (
      addIDs.find(
        (id) => restrictedIds.includes(id) && addIDs.includes(guest?.id)
      )
    ) {
      error("Guest can't have additional permissions!");
      return false;
    } else return true;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    // get core permission ID for radio group
    const corePermissionID = values.core_permission;
    // get other permissions (checkboxes, switches)
    const selectedGroups = Object.keys(values).filter((key) => values[key]);
    const groupsIDs = groupsList
      .filter(({ type }) => selectedGroups.includes(type))
      .map(({ id }) => id);
    // Define groups to be added
    const groupsToAdd = [
      ...groupsIDs.map((id) => ({ id })),
      { id: corePermissionID },
    ];
    const addIDs = groupsToAdd.map(({ id }) => id);
    // permission specific validations
    if (
      !validateApprover(addIDs) ||
      !validateManufacturerEndUser(addIDs) ||
      !validateGuest(addIDs) ||
      !validateManufacturerApprover(addIDs)
    ) {
      setLoading(false);
      setSubmitting(false);
      return;
    }

    request(modifyUsersGroups, groupsToAdd, id)
      .then(() => {
        request(getUserGroups, id)
          .then((data) => {
            if (!data) return;
            info("User permissions have been updated!");
            setUserGroups(data);
          })
          .finally(() => setLoading(false));
      })
      .finally(() => setSubmitting(false));
  };

  if (!Object.keys(groupsList).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, values, resetForm }) => (
        <>
          {loading && <Loader title="Loading..." isOpen={loading} />}
          <div className="border border-secondary bg-white py-15 px-10">
            <div className="w-75">
              <div>
                <h3
                  className="pb-15"
                  style={{ borderBottom: "2px solid #64A70B" }}
                >
                  <strong>{name} Permissions</strong>
                </h3>
                <div
                  className="pl-5 pt-10"
                  style={{ borderBottom: "2px solid #64A70B" }}
                >
                  <div className="d-flex">
                    <div>
                      <AccessControl
                        desiredRoles={[AVAILABLE_ROLES.AGSAFE_STAFF]}
                        elseContent={
                          <MappedRadio
                            options={corePermissions}
                            name="core_permission"
                            labelName="name"
                            labelStyle={labelStyle}
                          />
                        }
                      >
                        <MappedRadio
                          options={corePermissions}
                          name="core_permission"
                          labelName="name"
                          labelStyle={labelStyle}
                          disabledOptions={[globalAdmin?.id, agsafeAdmin?.id]}
                        />
                      </AccessControl>
                    </div>
                    <div className="d-flex flex-column ml-20">
                      <MappedCheckbox
                        name="ca_access"
                        checked={values.ca_access}
                        label="Collection Agency"
                        labelStyle={labelStyle}
                      />
                      <MappedCheckbox
                        name="processor"
                        checked={values.processor}
                        label="Processor"
                        labelStyle={labelStyle}
                      />
                      <MappedCheckbox
                        name="manufacturer"
                        checked={values.manufacturer}
                        label="Manufacturer"
                        labelStyle={labelStyle}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-between mt-3 w-25">
                    <p className="mr-10 mt-3">Invoice Approver</p>
                    <MappedSwitch name="approver" checked={values.approver} />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3 w-25">
                    <p className="mr-10 mt-3">Manufacturer Approver</p>
                    <MappedSwitch
                      name="man_approver"
                      checked={values.man_approver}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-30 d-flex align-items-center justify-content-end">
              <div className="mr-3">
                <ListButton
                  label="Reset"
                  size="large"
                  onClick={handleReset(resetForm)}
                  variant="outlined"
                  text="#407A28"
                  boxShadow={false}
                />
              </div>
              <div className="mr-3">
                <ListButton
                  label="Submit"
                  size="large"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  boxShadow={false}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
