import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { OrganisationListPage } from "./pages/OrganisationListPage";
import { OrganisationCardPage } from "./pages/OrganisationCardPage";
import { OrganisationDetails } from "./modules/Organisations/OrganisationDetails";
import { UserSettingsPage } from "./pages/UserSettingsPage";
import { UsersListPage } from "./pages/UsersListPage";
import { UserCardPage } from "./pages/UserCardPage";
import { UserDetails } from "./modules/Users/UserDetails";
import { TagsListPage } from "./pages/TagsListPage";
import { TagCardPage } from "./pages/TagCardPage";
import { ContactsListPage } from "./pages/ContactsListPage";
import { ContactsCardPage } from "./pages/ContactsCardPage";
import { IntegrationsPage } from "./pages/IntegrationsPage";
import { BackupPage } from "./pages/BackupPage";
import { GuestPage } from "./pages/GuestPage";
// import { PoliciesListPage } from "./pages/PoliciesListPage";
import { ChemClearPage } from "./pages/ChemClearPage";
import { ProductsListPage } from "./pages/ProductsListPage";
import { ProductDetails } from "./modules/Products/ProductDetails";
import { ProductCardPage } from "./pages/ProductCardPage";
import { SitesListPage } from "./pages/SitesListPage";
import { SiteCardPage } from "./pages/SiteCardPage";
import { ReimbursementsList } from "./pages/ReimbursementsList";
import { Inspections } from "./modules/DrumMuster/Inspections";
import { Tasks } from "./modules/Tasks";
import { DrumMusterBooking } from "./modules/DrumMusterBooking";
import { BadgesListPage } from "./pages/BadgesListPage";
import { BadgeCardPage } from "./pages/BadgeCardPage";
import { ConsultantsListPage } from "./pages/ConsultantsListPage";
import { ConsultantsCardPage } from "./pages/ConsultantsCardPage";
import { RegionCardPage } from "./pages/RegionCardPage";
import { RegionsListPage } from "./pages/RegionsListPage";
import { ChemClearBooking } from "./modules/ChemClearBooking";
import { ReimbursementsRec } from "./pages/ReimbursementsRecPage";
import { Processing } from "./modules/Processing";
import { Scheduling } from "./modules/Scheduling";
import { IsGuest } from "../helpers/IsGuest";
import { ContactDetails } from "./modules/Contacts/ContactDetails";
import { Claims } from "./modules/DrumMuster/Claims";
import { PackSizesListPage } from "./pages/PackSizesListPage";
import { PackSizesCardPage } from "./pages/PackSizesCardPage";
import { PriceCardPage } from "./pages/PriceCardPage";
import { PricesListPage } from "./pages/PricesListPage";
import { RouteCard } from "./modules/Processing/RouteCard";
import { Line } from "./modules/ReimbursementsReconciliation/Line";
import { Backtrack } from "./modules/Backtrack";
import { ManufacturerDashobard } from "./modules/Manufacturers/ManufacturerDashboard";
import { ManufacturerSummary } from "./modules/Manufacturers/ManufacturerSummary";
import { QuarterlyDeclaration } from "./modules/Manufacturers/QuarterlyDeclaration";
import { ManufacturerReview } from "./modules/Manufacturers/ManufacturerReview";
import { IsManufacturer } from "../helpers/IsManufacturer";
import { NewQuarterlyDeclaration } from "./modules/Manufacturers/QuarterlyDeclaration/NewQuarterlyDeclaration";

const getDefaultPage = () => {
  if (IsGuest()) return "/guest";
  else if (IsManufacturer()) return "/manufacturers/dashboard";
  else return "/dashboard";
};

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={getDefaultPage()} />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />

        <ContentRoute path="/user/edit" component={UserSettingsPage} />

        <ContentRoute
          path="/organisations/organisations-list"
          component={OrganisationListPage}
        />
        <Route
          path="/organisations-list/:id"
          component={OrganisationCardPage}
        />
        <ContentRoute
          path="/add-organisation"
          component={OrganisationDetails}
        />

        <ContentRoute path="/contacts-list" component={ContactsListPage} />
        <Route path="/contacts/:id" component={ContactsCardPage} />
        <ContentRoute path="/add-contact" component={ContactDetails} />

        <ContentRoute path="/users-list" component={UsersListPage} />
        <Route path="/users/:id" component={UserCardPage} />
        <ContentRoute path="/add-user" component={UserDetails} />

        <ContentRoute path="/settings/tags" component={TagsListPage} />
        <Route path="/tags/:id" component={TagCardPage} />
        <ContentRoute path="/add-tag" component={TagCardPage} />

        <ContentRoute
          path="/settings/pack-sizes"
          component={PackSizesListPage}
        />
        <Route path="/pack-sizes/:id" component={PackSizesCardPage} />
        <ContentRoute path="/add-pack-size" component={PackSizesCardPage} />

        <ContentRoute path="/integrations" component={IntegrationsPage} />

        <ContentRoute path="/chem-clear" component={ChemClearPage} />

        <ContentRoute path="/products-list" component={ProductsListPage} />
        <Route path="/products/:id" component={ProductCardPage} />
        <ContentRoute path="/add-product" component={ProductDetails} />

        <ContentRoute path="/sites-list" component={SitesListPage} />
        <Route path="/sites/:id" component={SiteCardPage} />
        <ContentRoute path="/add-site" component={SiteCardPage} />

        <ContentRoute path="/settings/badges" component={BadgesListPage} />
        <Route path="/badges/:id" component={BadgeCardPage} />
        <ContentRoute path="/add-badge" component={BadgeCardPage} />

        <ContentRoute path="/settings/prices" component={PricesListPage} />
        <Route path="/prices/:id" component={PriceCardPage} />
        <ContentRoute path="/add-price" component={PriceCardPage} />

        <ContentRoute
          path="/settings/consultants"
          component={ConsultantsListPage}
        />
        <Route path="/consultants/:id" component={ConsultantsCardPage} />
        <ContentRoute path="/add-consultant" component={ConsultantsCardPage} />

        <ContentRoute path="/settings/regions" component={RegionsListPage} />
        <Route path="/regions/:id" component={RegionCardPage} />
        <ContentRoute path="/add-region" component={RegionCardPage} />

        <ContentRoute
          path="/approve-reimbursements"
          component={ReimbursementsList}
        />
        <ContentRoute path="/drummuster/inspections" component={Inspections} />
        <ContentRoute
          path="/review/reconciliation-reimbursements"
          component={ReimbursementsRec}
        />

        <ContentRoute
          path="/review/declarations"
          component={ManufacturerReview}
        />

        <Route path="/reconciliation/:id" component={Line} />

        <ContentRoute path="/backtrack" component={Backtrack} />

        <Route path="/processing/:id" component={RouteCard} />

        <ContentRoute path="/tasks" component={Tasks} />

        <ContentRoute path="/error-page" component={BackupPage} />

        <ContentRoute path="/guest" component={GuestPage} />

        <ContentRoute
          path="/drummuster-booking"
          component={DrumMusterBooking}
        />
        <ContentRoute path="/chemclear-booking" component={ChemClearBooking} />

        <ContentRoute path="/drummuster/processing" component={Processing} />
        <ContentRoute path="/drummuster/scheduling" component={Scheduling} />
        <ContentRoute path="/drummuster/claims" component={Claims} />
        <Route path="/claims/:id" component={Inspections} />

        <ContentRoute
          path="/manufacturers/dashboard"
          component={ManufacturerDashobard}
        />
        <Route
          path="/manufacturers/declaration/:id"
          component={QuarterlyDeclaration}
        />
        <ContentRoute
          path="/manufacturers/summary"
          component={ManufacturerSummary}
        />
        <ContentRoute
          path="/manufacturers/new-declaration"
          component={NewQuarterlyDeclaration}
        />

        {/* <Route path="/groups/:id" component={PoliciesListPage} /> */}

        <Redirect to="/error-page" />
      </Switch>
    </Suspense>
  );
}
