/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { HEADINGS } from "../../../modules/Manufacturers/QuarterlyDeclaration/constants";
import {
  modifyProductsArray,
  renderDeclarationsRow,
  renderTotalLevyDue,
} from "../../../modules/Manufacturers/QuarterlyDeclaration/helpers";
import { Checkbox } from "../../../../ui/components/Checkbox";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { Formik } from "formik";
import { NewDeclarationSchema } from "../../../../helpers/schemas";
import { get } from "lodash";
import { error } from "../../../../helpers/toasts";
import { ListButton } from "../../../../ui/components/ListButton";

export const DeclarationCard = ({ data = {}, handleClose }) => {
  const initialValues = {
    organisation_name: get(data, "organisation.business_name", ""),
    year: data?.year || "",
    quarter: data?.real_quarter || "",
    contact_name: [
      get(data, "contact.first_name", ""),
      get(data, "contact.last_name", ""),
    ].join(" "),
    date_entered: data.submitted_at
      ? new Date(data.submitted_at)
      : new Date(Date.now()),
    purchase_order_number: data?.purchase_order_number || "",
  };

  const createDownloadLink = (downloadUrl) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
  };

  const downloadEvidence = () => {
    const evidenceUrl = data.evidence_url;
    if (evidenceUrl) {
      createDownloadLink(evidenceUrl);
    } else {
      error("Evidence not found!");
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={NewDeclarationSchema}
        onSubmit={() => {}}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
          <div className="row justify-content-center mt-10">
            <div className="col-12">
              <div className="bg-white rounded pb-7 px-10">
                <div style={{ marginLeft: "-10px" }} className="mb-5">
                  <IconButton onClick={handleClose}>
                    <ArrowBackIcon />
                  </IconButton>
                </div>
                <div>
                  <div className="row justify-content-between">
                    <div className="col-3">
                      <MappedInput
                        name="organisation_name"
                        label="Organisation"
                        disabled
                      />
                    </div>
                    <div className="col-2">
                      <MappedInput name="year" label="Year" disabled />
                    </div>

                    <div className="col-1">
                      <MappedInput name="quarter" label="Quarter" disabled />
                    </div>
                    <div className="col-2">
                      <MappedInput
                        name="contact_name"
                        label="Contact"
                        disabled
                      />
                    </div>
                    <MappedDatePicker
                      name="date_entered"
                      label="Date entered"
                      shouldCloseOnSelect
                      disabled
                    />
                    <div className="col-3">
                      <MappedInput
                        name="purchase_order_number"
                        label="Purchase Order No. (optional)"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="my-5 d-flex justify-content-between align-items-center">
                  <Checkbox
                    checked={data.status === "nil_declared"}
                    s
                    label="Nil Declared"
                    onChange={() => []}
                    disabled
                  />
                  <ListButton
                    label="Download Evidence"
                    size="small"
                    className="px-10 mr-5"
                    onClick={downloadEvidence}
                    disabled={!data.evidence_url}
                  />
                </div>
                <ListHeader title="My Products" />
                <div className="mt-10">
                  <Datagrid
                    data={modifyProductsArray(data.declaration_products)}
                    headings={HEADINGS}
                    renderRow={renderDeclarationsRow}
                    paginationOptions={[50, 100, 200]}
                    paginationDefaultValue={50}
                    footerRow={(data) => renderTotalLevyDue(data)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
