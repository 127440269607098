import React from "react";
import {
  isStatus,
  getStatusRowStyle,
  isDate,
  formatDate,
  isPrice,
  formatPrice,
} from "../../../../ui/helpers";
import { SummaryDownload } from "./SummaryDownload";
import { get } from "lodash";

const statusMap = {
  draft: "due",
  active: "submitted",
  awaiting_payment: "invoiced",
  paid: "paid",
  nil_declared: "nil_declared",
  archived: "archived",
};

export const getDeclarationStatus = (declaration = {}) => {
  return statusMap[declaration.status];
};

const tranformDate = (date) => {
  return date ? formatDate(date) : null;
};

export function renderSummaryRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${
        isStatus(key) && getStatusRowStyle(item)
      }`}
    >
      {isDate(key) ? (
        tranformDate(item[key])
      ) : isPrice(key) ? (
        formatPrice(item[key])
      ) : isStatus(key) ? (
        item[key].split("_").join(" ")
      ) : key === "download" ? (
        <SummaryDownload item={item} />
      ) : (
        item[key]
      )}
    </td>
  ));
}

const quarterMap = {
  1: "Q1 Jul-Sep",
  2: "Q2 Oct-Dec",
  3: "Q3 Jan-Mar",
  4: "Q4 Apr-Jun",
};

export function modifySummary(declaration = {}) {
  return {
    ...declaration,
    status: getDeclarationStatus(declaration),
    organisation_name: get(declaration, "organisation.business_name", ""),
    submitted_by: get(declaration, "submitter.name", ""),
    quarter: declaration.instance_number
      ? `${declaration.year}, ${quarterMap[declaration.quarter]}/${
          declaration.instance_number
        }`
      : `${declaration.year}, ${quarterMap[declaration.quarter]}`,
    real_quarter: declaration.quarter,
    total_due: declaration.total_value + declaration.total_value * 0.1,
    total_qty: (declaration.declaration_products || []).reduce(
      (acc, value) => acc + value.quantity,
      0
    ),
    payment_date: get(declaration, "invoice.payment_date", ""),
  };
}

export function modifySummaryArray(declarations = []) {
  return declarations.map((item) => modifySummary(item));
}

export const modifyDeclarationsSortKey = (key) => {
  if (key === "organisation_code") return "organisation.code";
  else if (key === "organisation_name") return "organisation.business_name";
  else if (key === "submitted_by") return "submitter.name";
  else if (key === "payment_date") return "invoice.payment_date";
  else return key;
};
