import { get } from "lodash";
import { useSelector } from "react-redux";

export const IsManufacturer = () => {
  const user = useSelector(({ auth: { user } }) => user) || {};

  const isManufacturer = get(user, "permissions.is_manufacturer");

  return isManufacturer;
};
