import React from "react";
import ToolTip from "../components/ToolTip";
import { Tooltip } from "@material-ui/core";
import { FiberManualRecord as CircleIcon } from "@material-ui/icons";
import { uniq } from "lodash";
import { error, info } from "../../helpers/toasts";

export function isLink(key) {
  return [
    "business_name",
    "first_name",
    "last_name",
    "id",
    "name",
    "code",
    "processor_name",
  ].includes(key);
}

export function isArray(key) {
  return ["states"].includes(key);
}

export function isStatus(key) {
  return ["status", "payment_status"].includes(key);
}

export function isDonwloadKey(key) {
  return ["download_pdf"].includes(key);
}

export function isPrice(key) {
  return [
    "price",
    "cost",
    "sale_price",
    "sales_price",
    "item_price",
    "transaction_total",
    "transaction_tax",
    "amount",
    "value",
    "inspector_fee",
    "line_total",
    "processing_cost",
    "per_unit_cost",
    "total_value",
    "total_value_gst",
    "total_due",
    "total_amount",
    "unit_price",
  ].includes(key);
}

export function isDate(key) {
  return [
    "submit_date",
    "receive_date",
    "result_date",
    "start_date",
    "end_date",
    "invoice_date",
    "date",
    "date_requested",
    "planned_date",
    "inspection_date",
    "created_at",
    "expires_in",
    "completed_at",
    "expires",
    "last_stocktake",
    "planned_pickup_date",
    "pickup_date",
    "completed_date",
    "due_date",
    "last_login",
    "planned_pickup_date",
    "history_created_at",
    "drop_off_date",
    "closing_date",
    "submitted_at",
    "payment_date"
  ].includes(key);
}

export function isFTCertificate(key) {
  return ["ft_cert"].includes(key);
}

export function isNATACertificate(key) {
  return ["nata_cert"].includes(key);
}

export function getTip(meta = {}, tip) {
  if (meta.touched && meta.error) {
    return <span className="text-danger">{meta.error}</span>;
  }
  if (tip) {
    return <span className="text-muted">{tip}</span>;
  }
}

export function renderRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isDate(key) && item[key] ? formatDate(item[key]) : item[key]}
    </td>
  ));
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = month < 10 ? "0" + month : month;
    return day + "/" + monthWithNull + "/" + d.getFullYear();
  }
  console.error("Not a valid Date");
}

export function formatFileName(name = "") {
  return name.split("-").slice(1).join("-");
}

export function sortByDate(a, b) {
  return new Date(b.last_modified) - new Date(a.last_modified);
}

export const onSearch = (search, data, dispatch, action, headings) => {
  dispatch(
    action(
      data.filter((item) => {
        const _search = Object.entries(search).map((item) => [
          (headings.find((i) => i[1] === item[0]) || [])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    )
  );
};

export const getSelectedId = (selected) => {
  for (let key in selected) {
    if (selected[key]) {
      return key;
    }
  }
};

export const getSelectedIDs = (selected) => {
  let selectedItems = [];
  for (let key in selected) {
    if (selected[key]) {
      selectedItems = [...selectedItems, key];
    }
  }
  return selectedItems;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index + 1),
];

export function formatPrice(price) {
  if (typeof price !== "number") return;
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });
}

export const removeEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") delete obj[key];
  });
};

export const modifyEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") obj[key] = null;
  });
};

export const getStatusRowStyle = (item = {}) => {
  if (item.status === "archived") {
    return "text-uppercase text-danger";
  } else return "text-uppercase";
};

const exactMatchKeys = ["Material", "Status", "Size", "Group", "Region"];

const setSearchKey = (data = {}, searchMap, key) => {
  if (key === "Tag") {
    return `tags.name=${data.Tag}`;
  } else if (exactMatchKeys.includes(key)) {
    return `${searchMap[key]}=${encodeURIComponent(data[key])}`;
  } else if (key === "Pack Sizes") {
    return `pack_sizes.id=_null_`;
  } else if (searchMap[key]) {
    return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
  } else return "";
};

export const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map((key) => setSearchKey(data, searchMap, key))
      .join("&")
  );
  setPage && setPage(0);
};

export const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};

export function timeDiffCalc(endDate, startDate) {
  const dateFuture = new Date(endDate);
  const dateNow = new Date(startDate);
  let diffInMilliSeconds;

  // console.log(dateFuture, dateNow)

  diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600);
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day, ` : `${days} days, `;
  }

  difference += `${hours}:`;

  difference += minutes < 10 ? `0${minutes}` : minutes;

  return difference;
}

export const formatLongRow = (content = "", amount = 11) => {
  if (content === null) return content;
  if (content.length > amount) {
    return (
      <ToolTip
        content={<div>{content.slice(0, amount) + "..."}</div>}
        title={content}
      />
    );
  } else return content;
};

export function isValidMobile(item) {
  var matches = item["mobile"]?.match(
    new RegExp(
      /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/gm
    )
  );

  return matches && item.settings_sms ? (
    <>
      {item["mobile"]}{" "}
      <Tooltip title={`SMS enabled`} arrow interactive>
        <CircleIcon
          className="text-success pb-1"
          style={{ fontSize: "1rem", padding: 2 }}
        />
      </Tooltip>
    </>
  ) : (
    <>
      {item["mobile"]}{" "}
      {item["mobile"] && item["mobile"].replace(/\s/g, "").length ? (
        <Tooltip title={`SMS disabled`} arrow interactive>
          <CircleIcon
            className="text-danger pb-1"
            style={{ fontSize: "1rem", padding: 2 }}
          />
        </Tooltip>
      ) : (
        ""
      )}
    </>
  );
}

export const sortHelper = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export const objectCheck = (data = []) => {
  return data.every((item) => typeof item !== "object");
};

export const updateCardData = (newItem = {}, data, setData, updatedFiled) => {
  const index = data.findIndex(
    (item) => (item || {}).id === (newItem || {}).id
  );
  setData &&
    setData((state) => ({
      ...state,
      [updatedFiled]: [
        ...state[updatedFiled].slice(0, index),
        newItem,
        ...state[updatedFiled].slice(index + 1),
      ],
    }));
};

export const fetchAutocomplete = (
  autocompleteValue,
  setLoading,
  setData,
  request
) => {
  if (autocompleteValue === "") return;
  setLoading(true);
  request(autocompleteValue).then((data) => {
    if (data && Array.isArray(data) && data.length && objectCheck(data)) {
      setData(uniq(data.map((item) => String(item))));
    } else {
      setData(["No option found"]);
    }
    setLoading(false);
  });
};

export const handleCopy = (search) => {
  navigator.clipboard.writeText(search).then(
    function () {
      info("Copying was successful!");
    },
    function (err) {
      error("Could not copy text: ", err);
    }
  );
};

export const handlePaste = (SEARCH_HELPER, setCopiedSearch, setSearch) => {
  navigator.clipboard.readText().then((clipText) => {
    if (!clipText) return;
    const searchValues = clipText.split("&");
    const copiedSearch = searchValues.map((string) => {
      const keyMatch = string.substring(0, string.indexOf("="));
      const key = (SEARCH_HELPER.find((item) => item[0] === keyMatch) || [])[1];
      let value;
      if (key === "Tag") {
        value = string.substring(string.indexOf("=") + 1);
      } else value = decodeURIComponent((string.match(/\(([^)]+)\)/) || [])[1]);

      if (!key || !value) {
        error("Search match not found!");
        return {};
      }
      return { key, value };
    });
    setCopiedSearch(copiedSearch);
    setSearch(clipText);
  });
};

export const objectIsEmpty = (obj) => {
  for (var i in obj) return false;
  return true;
};
