import { reviewTypes } from "./actions";

const initialState = {
  reviewList: [],
};

export function manufacturerReviewReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case reviewTypes.SET_DATA:
      return {
        ...state,
        reviewList: payload,
      };

      case reviewTypes.ADD_DATA:
        return {
          ...state,
          reviewList: [...state, payload],
        };

    case reviewTypes.REMOVE_DATA:
      return {
        ...state,
        reviewList: [...state.reviewList.filter(({ id }) => id !== payload)],
      };

    case reviewTypes.UPDATE_DATA:
      const index = state.reviewList.findIndex(
        (item) => (item || {}).id === (payload || {}).id
      );
      return {
        ...state,
        reviewList: [
          ...state.reviewList.slice(0, index),
          payload,
          ...state.reviewList.slice(index + 1),
        ],
      };

    case reviewTypes.UPDATE_INVOICED:
      let newState = state.reviewList.map((declaration) => {
        const isSelectedItem = payload.includes(declaration?.id);
        if (isSelectedItem)
          return {
            ...declaration,
            status: "invoiced",
          };
        else return declaration;
      });
      console.log(newState)
      return {
        ...state,
        reviewList: newState,
      };

    default:
      return state;
  }
}
