export const summaryTypes = {
  SET_DATA: "SUMMARY/SET_DATA",
  REMOVE_DATA: "SUMMARY/REMOVE_DATA",
  UPDATE_DATA: "SUMMARY/UPDATE_DATA",
};

export function setSummaryList(data) {
  return {
    type: summaryTypes.SET_DATA,
    payload: data,
  };
}

export function removeSummaryItem(data) {
  return {
    type: summaryTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateSummaryList(data) {
  return {
    type: summaryTypes.UPDATE_DATA,
    payload: data,
  };
}
