import React, { useEffect } from "react";
import { useFormikContext } from "formik";

export const TotalAmount = ({ matches, setMatches }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    const calculatedTotal = (
      Number(values.nsw_percent) +
      Number(values.nt_percent) +
      Number(values.tas_percent) +
      Number(values.qld_percent) +
      Number(values.wa_percent) +
      Number(values.sa_percent) +
      Number(values.act_percent) +
      Number(values.vic_percent)
    ).toFixed(2);
    if (Number(calculatedTotal) === 100) setMatches(true);
    else setMatches(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.nsw_percent,
    values.nt_percent,
    values.tas_percent,
    values.qld_percent,
    values.wa_percent,
    values.sa_percent,
    values.act_percent,
    values.vic_percent,
  ]);

  return matches ? (
    <p className="text-primary">Your Totals Match</p>
  ) : (
    <p className="text-danger">
      Totals don’t match, please check before submitting
    </p>
  );
};
