import React from "react";
import {
  isStatus,
  getStatusRowStyle,
  isPrice,
  formatPrice,
} from "../../../../ui/helpers";

const calculatePercentageByState = (product = {}) => {
  let finalString = "";
  const states = [
    { NSW: product.nsw_percent },
    { NT: product.nt_percent },
    { TAS: product.tas_percent },
    { QLD: product.qld_percent },
    { WA: product.wa_percent },
    { SA: product.sa_percent },
    { ACT: product.act_percent },
    { VIC: product.vic_percent },
  ].filter((item) => Object.values(item)[0]);
  states.map((item) => {
    const key = Object.keys(item)[0];
    const modifiedString = `${item[key]}% ${key}, `;
    return (finalString += modifiedString);
  });
  return finalString.slice(0, -2);
};

export function renderDeclarationsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${
        isStatus(key) && getStatusRowStyle(item)
      }`}
    >
      {isPrice(key) ? (
        formatPrice(item[key])
      ) : key === "material" ? (
        <span className="text-capitalize">{item[key]}</span>
      ) : key === "size" ? (
        `${item[key]}L`
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const renderTotalLevyDue = (data = []) => {
  const sum = data.reduce((acc, value) => acc + Number(value.total_due), 0);
  return (
    <>
      <tr className="border-bottom">
        <td colSpan="4"></td>
        <td className="no-line border-0 text-right px-5 pb-1 pt-3">
          Total ex.GST
          <br />
          GST
        </td>
        <td className=" pb-1 pt-3 pl-5">
          {formatPrice(sum)} <br /> {formatPrice(sum * 0.1)} <br />
        </td>
      </tr>
      <tr>
        <td colSpan="4"></td>
        <td className="no-line border-0 text-right px-5 pb-1 pt-3">
          Total incl. GST
        </td>
        <td className=" pb-1 pt-3 pl-5">{formatPrice(sum + sum * 0.1)}</td>
      </tr>
    </>
  );
};

export function modifyProduct(product = {}) {
  return {
    ...product,
    total_due: product.size * product.quantity * product.unit_price,
    percentage_by_state: calculatePercentageByState(product),
  };
}

export function modifyProductsArray(products = []) {
  return products.map((item) => modifyProduct(item));
}
