import React, { useState } from "react";
import { Chip, Button } from "@material-ui/core";
import { get } from "lodash";
import ReportIcon from "../../../../ui/static/Report.png";
import { error, info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";

const getChipStyle = (type) => {
  if (type === "due")
    return {
      color: "#E53935",
      backgroundColor: "#FCE7E7",
    };
  else
    return {
      color: "#287A14",
      backgroundColor: "#CCFDE0",
    };
};

export const DeclarationItem = ({ item = {} }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    if (!item.invoice_id) {
      info("invoice not found!");
      return;
    }
    setLoading(true);
    try {
      fetch(
        `https://api.dev.agsafe.org.au/declarations/invoices/${item.invoice_id}/download`,
        {
          mode: "cors",
          credentials: "include",
        }
      )
        .then((data) => data.text())
        .then((data) => {
          const downloadUrl = "data:application/octet-stream;base64," + data;
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = `${get(item, "invoice.code", "invoice")}.pdf`;
          link.setAttribute("target", "_blank");
          link.setAttribute("rel", "noopener noreferrer");
          link.click();
        })
        .finally(() => setLoading(false));
    } catch {
      error("Something went wrong!");
    }
  };

  return (
    <div className="bg-white p-5 mb-3 border shadow rounded">
      {loading && (
        <Loader
          title="Downloading PDF..."
          onClose={() => setLoading(false)}
          isOpen={loading}
        />
      )}
      <div className="d-flex justify-content-between align-items-center">
        <div className="col-6">
          <p className="font-weight-bold">{item.name}</p>
          <p>
            <span className="text-muted">Due Date :&nbsp;&nbsp;&nbsp;</span>
            <span>
              {item.due_date && new Date(item.due_date).toDateString()}
            </span>
          </p>
          <p>
            <span className="text-muted">Quarter :&nbsp;&nbsp;&nbsp;</span>
            <span>{item.quarter}</span>
          </p>
          <p>{get(item, "organisation.business_name", "")}</p>
        </div>
        <div className="text-capitalize">
          <Chip
            label={item.status.split("_").join(" ")}
            style={{
              minWidth: "180px",
              fontSize: "14px",
              ...getChipStyle(item.status),
            }}
          />
          <Button
            data-testid="download"
            onClick={handleDownload}
            disabled={!item.invoice_id || loading}
          >
            <span className="cursor-pointer">
              <img
                src={ReportIcon}
                alt="Report"
                style={{ opacity: item.invoice_id ? 1 : 0.6 }}
              />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
