import { Skeleton } from "@material-ui/lab";
import React, { useState, useEffect, useMemo } from "react";
import { info } from "../../../helpers/toasts";
import { useFetch } from "../../../hooks/fetch.hook";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { ListButton } from "../../../ui/components/ListButton";
import { Loader } from "../../../ui/components/Loader";
import {
  getQuickbooksCustomers,
  getQuickbooksVendors,
  modifyOrganisation,
} from "./_api";

export const QuickBooks = ({
  organisationId,
  vendorId = "",
  customerId = "",
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");

  const setVendor = (name, value) => {
    setSelectedId(value);
  };

  const setCustomer = (name, value) => {
    setSelectedCustomerId(value);
  };

  const handleSubmit = () => {
    const payload = {
      quickbooks_vendor_id: selectedId,
    };
    setLoading(true);
    request(modifyOrganisation, payload, organisationId)
      .then((data) => data && info("Organisation has been matched!"))
      .finally(() => setLoading(false));
  };

  const handleCustomerSubmit = () => {
    const payload = {
      quickbooks_customer_id: selectedCustomerId,
    };
    setLoading(true);
    request(modifyOrganisation, payload, organisationId)
      .then((data) => data && info("Organisation has been matched!"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      request(getQuickbooksVendors),
      request(getQuickbooksCustomers),
    ])
      .then(([vendors, customers]) => {
        vendors && setVendors(vendors);
        customers && setCustomers(customers);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vendorOptions = useMemo(
    () =>
      vendors.map((venodr) => ({
        value: venodr.Id,
        label: venodr.CompanyName,
      })),
    [vendors]
  );

  const customerOptions = useMemo(
    () =>
      customers.map((customer) => ({
        value: customer.Id,
        label: customer.CompanyName,
      })),
    [customers]
  );

  if (!vendors.length || !customers.length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="d-flex justify-content-between">
        <div className="w-50">
          <h3 className="mb-10">
            <strong>Match Quickbooks Vendor</strong>
          </h3>
          <div className="w-75">
            <div>
              <Autocomplete
                name=""
                placeholder="Quickbooks Vendors"
                setValue={setVendor}
                defaultValueField="value"
                options={vendorOptions}
                className="mb-3"
                defaultValue={String(vendorId)}
              />
            </div>
            <div className="text-right mt-10">
              <ListButton
                label="Save"
                size="large"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={loading}
              />
            </div>
          </div>
        </div>
        <div className="w-50">
          <h3 className="mb-10">
            <strong>Match Quickbooks Customer</strong>
          </h3>
          <div className="w-75">
            <div>
              <Autocomplete
                name=""
                placeholder="Quickbooks Customers"
                setValue={setCustomer}
                defaultValueField="value"
                options={customerOptions}
                className="mb-3"
                defaultValue={String(customerId)}
              />
            </div>
            <div className="text-right mt-10">
              <ListButton
                label="Save"
                size="large"
                boxShadow={false}
                onClick={handleCustomerSubmit}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
