import { get } from "lodash";
import React from "react";
import {
  formatDate,
  isStatus,
  isDate,
  isPrice,
  formatPrice,
} from "../../../../ui/helpers";
import { EditableRow } from "../../../../ui/structures/EditableRow";

const tranformDate = (date) => {
  return date ? formatDate(date) : null;
};

export function renderReimbursementsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 "text-dark" ${
        isStatus(key) ? "text-uppercase" : ""
      }`}
    >
      {isDate(key) ? (
        tranformDate(item[key])
      ) : isPrice(key) ? (
        <div className="text-right">{formatPrice(item[key])}</div>
      ) : (
        item[key]
      )}
    </td>
  ));
}

const handleDataUpdate = (item = {}, data = [], setData) => {
  const index = data.findIndex((record) => (record || {}).id === item.id);
  setData((state) => [
    ...state.slice(0, index),
    item,
    ...state.slice(index + 1),
  ]);
};

export const renderExtendedRow = (item = {}, data, setData) => {
  const handleUpdate = (payload) => {
    handleDataUpdate(payload, data, setData);
  };
  const colSpanValue = item.reimbursement_id ? 4 : 5;
  return (
    <EditableRow
      data={item}
      handleDataUpdate={handleUpdate}
      colSpanValue={colSpanValue}
    />
  );
};

const dataKeys = (data = []) => {
  return data.reduce((acc, item = {}) => {
    acc[item.id] = true;
    return Object.keys(acc);
  }, []);
};

export const getRandomNumber = (max) =>
  Math.floor(Math.random() * Math.floor(max));

export const setID = (max, data = []) => {
  let random = getRandomNumber(max);
  const keysArr = dataKeys(data);
  if (!(keysArr || []).find((item) => item === random)) {
    return random;
  } else setID(max);
};

export const getTotalPlastic = (item = {}) => {
  if (!Array.isArray(item.ccds)) return 0;
  return item.ccds.reduce((acc, value) => {
    if (value.material === "plastic") {
      return acc + (Number(value.quantity) || 0);
    } else return acc + 0;
  }, 0);
};

export const getTotalSteel = (item = {}) => {
  if (!Array.isArray(item.ccds)) return 0;
  return item.ccds.reduce((acc, value) => {
    if (value.material === "steel") {
      return acc + (Number(value.quantity) || 0);
    } else return acc + 0;
  }, 0);
};

export const getTotalContainers = (item = {}) => {
  if (Array.isArray(item.ccds)) {
    return item.ccds.reduce(
      (acc, value) => acc + (Number(value.quantity) || 0),
      0
    );
  } else return 0;
};

export function modifyCCD(item = {}) {
  return {
    ...item,
    associated_site: get(item, "site.name"),
    code: get(item, "site.code"),
    business: get(item, "organisation.business_name", ""),
    name: [
      get(item, "contact.first_name", ""),
      get(item, "contact.last_name", ""),
    ].join(" "),
    inspector: [
      get(item, "inspector.first_name", ""),
      get(item, "inspector.last_name", ""),
    ].join(" "),
    total_steel: getTotalSteel(item),
    total_plastic: getTotalPlastic(item),
    total: getTotalContainers(item),
    plastic_0: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20" && material === "plastic"
      ) || {}
    ).quantity,
    plastic_1: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20-25" && material === "plastic"
      ) || {}
    ).quantity,
    plastic_2: (
      (item.ccds || []).find(
        ({ size, material }) => size === "26-120" && material === "plastic"
      ) || {}
    ).quantity,
    plastic_3: (
      (item.ccds || []).find(
        ({ size, material }) => size === "121-205" && material === "plastic"
      ) || {}
    ).quantity,
    steel_0: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20" && material === "steel"
      ) || {}
    ).quantity,
    steel_1: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20-25" && material === "steel"
      ) || {}
    ).quantity,
    steel_2: (
      (item.ccds || []).find(
        ({ size, material }) => size === "26-120" && material === "steel"
      ) || {}
    ).quantity,
    steel_3: (
      (item.ccds || []).find(
        ({ size, material }) => size === "121-205" && material === "steel"
      ) || {}
    ).quantity,
    eccd: get(item, "eccd"),
  };
}

export function modifyCCDsArray(list = []) {
  return list.map((item) => modifyCCD(item));
}

export const getStatusValue = (search = "") => {
  if (search.includes("status=waiting_for_invoice")) {
    return "&status=waiting_for_invoice";
  } else if (search.includes("status=admin_review")) {
    return "&status=admin_review";
  } else return "&status=waiting_for_invoice&status=admin_review";
};
