/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getReviewReimbursements,
  getReviewReimbursementsAmount,
  getReviewReimbursementsAutocomplete,
} from "../DrumMuster/Inspections/_api";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { renderReimbursementsRow } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import {
  calculateInvoiceTotalGST,
  modifyReimbursementsArray,
} from "../ReimbursementsApprovals/helpers";
import { getSelectedId, handleSearch, objectCheck } from "../../../ui/helpers";
import {
  exportReimbursements,
  printReimbursements,
} from "../ReimbursementsApprovals/_api";
import { Loader } from "../../../ui/components/Loader";
import { downloadFile } from "../AWS-S3/download";
import { HEADINGS, SEARCH_HELPER, searchMap } from "./constants";
import { useHistory } from "react-router-dom";
import { recalculateReimbursement } from "./_api";
import { info } from "../../../helpers/toasts";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";

export const List = () => {
  const { request } = useFetch();
  const history = useHistory();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [data, setData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    return request(
      getReviewReimbursementsAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields
    );
  };

  const fetchReimbursementsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      (data) => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          const autocompleteData = uniq(data.map((item) => String(item)));
          if (autocompleteField === "status") {
            setAutocompleteData(
              autocompleteData.filter(
                (item) =>
                  item === "waiting_for_invoice" || item === "admin_review"
              )
            );
          } else {
            setAutocompleteData(uniq(data.map((item) => String(item))));
          }
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  };

  const fetchReimbursements = () => {
    setLoading(true);
    return request(getReviewReimbursements, search, page, pageSize)
      .then((data) => data && setData(modifyReimbursementsArray(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReimbursements();
  }, [search, page, pageSize]);

  useEffect(() => {
    request(getReviewReimbursementsAmount, search).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  const resetSearch = () => setSearch("");

  const handleExpand = (id) => () => {
    history.push(`/reconciliation/${id}`);
  };

  const handlePrint = () => {
    setActionLoading(true);
    downloadFile(null, null, setActionLoading, printReimbursements);
  };

  const handleExport = () => {
    setActionLoading(true);
    downloadFile(null, null, setActionLoading, exportReimbursements);
  };

  const handleDataUpdate = (record = {}) => {
    const index = data.findIndex(
      (item) => (item || {}).id === (record || {}).id
    );
    const item = data.find((item) => item?.id === record?.id) || {};
    if (index === -1) return;
    const payload = {
      ...item,
      total_value: record.total_value,
      total_value_gst: calculateInvoiceTotalGST(record),
    };
    setData((state) => [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1),
    ]);
  };

  const handleCalculate = () => {
    const itemID = getSelectedId(selected);
    if (!itemID) return;
    setActionLoading(true);
    request(recalculateReimbursement, itemID)
      .then((data) => {
        if (!data) return;
        handleDataUpdate(data);
        info("Value has been recalculated!");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const recalculateDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const renderButtons = () => {
    return (
      <>
        <ListButton
          label="Recalculate Value"
          size="medium"
          className="mr-3"
          onClick={handleCalculate}
          disabled={recalculateDisabled}
          data-testid="recalculate"
        />
        <ListButton
          label="Print Report"
          size="medium"
          className="mr-3"
          onClick={handlePrint}
          data-testid="print-report"
        />
        <ListButton
          label="Export"
          size="medium"
          onClick={handleExport}
          data-testid="export"
        />
      </>
    );
  };

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearch(data, searchMap, setSearch, setPage)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchReimbursementsAutocomplete}
              placeholder="Filter..."
              clearSearch={resetSearch}
              searchMap={searchMap}
              searchFields={search}
              contentLoading={loading}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Review List" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderReimbursementsRow}
              selectable
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              expandable
              handleExpand={handleExpand}
              expandHeader="More"
              expandIcon={
                <ArrowRightAltIcon className="ml-5" fontSize="large" />
              }
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
