import { declarationTypes } from "./actions";

const initialState = {
  declarationList: [],
};

export function quarterlyDeclarationReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case declarationTypes.SET_DATA:
      return {
        ...state,
        declarationList: payload,
      };

    case declarationTypes.ADD_ITEM:
      return {
        ...state,
        declarationList: [...state.declarationList, payload],
      };

    case declarationTypes.REMOVE_DATA:
      return {
        ...state,
        declarationList: [
          ...state.declarationList.filter(({ id }) => id !== payload),
        ],
      };

    case declarationTypes.UPDATE_DATA:
      const index = state.declarationList.findIndex(
        (item) => (item || {}).id === (payload || {}).id
      );
      return {
        ...state,
        declarationList: [
          ...state.declarationList.slice(0, index),
          payload,
          ...state.declarationList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
