import config from "react-global-configuration";
import { getStatusValue } from "../helpers";

export const createDrops = (payload) =>
  fetch(`${config.get("ApiUrl").Rest}/drop-offs`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });

export const editDrop = (payload, id) =>
  fetch(`${config.get("ApiUrl").Rest}/drop-offs/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });

export const getDrops = (
  query = "",
  field,
  direction,
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/drop-offs?${query}${
      (field && "&order_by=" + field) || ""
    }${
      (direction && "&order_dir=" + direction) || ""
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getDropsCount = (query = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/drop-offs?count_only=y${
      query ? "&" + query : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getDropById = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/drop-offs/${id}`, {
    mode: "cors",
    credentials: "include",
  });

export const getReimbursementDrops = (reimbursement_id, query = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements/${reimbursement_id}/drop-offs?${query}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const editReimbursementDrops = (reimbursement_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/reimbursements/${reimbursement_id}/drop-offs`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const createReimbursements = (payload) =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });

export const deleteReimbursement = (id, payload) =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/${id}`, {
    method: "DELETE",
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });

export const getReimbursements = (status = "", search = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?${
      status && `status=${status}`
    }${search && "&" + search}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getReimbursementsPaginated = (
  search = "",
  status = "",
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?${search && "&" + search}${
      (status && `&status=${status}`) || ""
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getHistoryReimbursements = (
  organisationId,
  search = "",
  page = 0,
  page_size = 10,
  field,
  direction
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements?status=approved&organisation_id=${organisationId}${
      search && "&" + search
    }${(field && "&order_by=" + field) || ""}${
      (direction && "&order_dir=" + direction) || ""
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getReimbursementsAmount = (search = "", status = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?count_only=y${
      search ? "&" + search : ""
    }${(status && `&status=${status}`) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getClaimsListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search,
  status,
  organisationId
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?&page_size=${page_size}${
      (status && `&status=${status}`) || ""
    }${
      (autocompleteField &&
        "&distinct=" +
          autocompleteField +
          "&" +
          autocompleteField +
          "=_like(" +
          autocompleteText +
          ")_") ||
      ""
    }${search ? "&" + search : ""}${
      organisationId ? `&organisation_id=${organisationId}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getReviewReimbursements = (
  search = "",
  page = 0,
  page_size = 10
) => {
  const status = getStatusValue(search);
  return fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?${status}${
      search && "&" + search
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getReviewReimbursementsAmount = (search = "") => {
  const status = getStatusValue(search);
  return fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?count_only=y&${status}${
      search && "&" + search
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getReviewReimbursementsAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) => {
  return fetch(
    `${config.get("ApiUrl").Rest}/reimbursements?page_size=${page_size}${
      (autocompleteField &&
        "&distinct=" +
          autocompleteField +
          "&" +
          autocompleteField +
          "=_like(" +
          autocompleteText +
          ")_") ||
      ""
    }${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getReviewReimbursementById = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/${id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyInvoice = (data, id, invoice_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/reimbursements/${id}/invoices/${invoice_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyInvoiceFile = (data, id, invoice_id, file_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements/${id}/invoices/${invoice_id}/files/${file_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyReimbursement = (data, id) =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getSiteCCDs = (site_id) =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/ccds`, {
    mode: "cors",
    credentials: "include",
  });

export const abrLookup = (query) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/abn-lookup?search_only=true&${query}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const groupReimbursements = (id, payload) =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/${id}/group`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });
