/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import Input from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { nonSortableManufacturerHeadings } from "../../../modules/Manufacturers/ManufacturerSummary/constants";
import {
  modifyDeclarationsSortKey,
  modifySummaryArray,
  renderSummaryRow,
} from "../../../modules/Manufacturers/ManufacturerSummary/helpers";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import {
  getDeclarations,
  getDeclarationsAmount,
} from "../../../modules/Manufacturers/_api";
import { ListButton } from "../../../../ui/components/ListButton";
import { DECLARATIONS_HEADINGS } from "../constants";

export const DeclarationsList = ({
  data = [],
  setData,
  organisationId,
  handleExpand,
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterYear, setFilterYear] = useState("");
  const [quarter, setQuarter] = useState("");

  const fetchDeclarations = () => {
    setLoading(true);
    request(
      getDeclarations,
      field,
      field && direction,
      page,
      pageSize,
      `organisation_id=${organisationId}&status=paid${
        filterYear && `&year=_like(${filterYear})_`
      }${quarter && `&quarter=${quarter}`}`
    )
      .then((data) => data && setData(modifySummaryArray(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    request(
      getDeclarationsAmount,
      `organisation_id=${organisationId}&status=paid`
    ).then((data) => data && setTotalRecords(data));
  }, []);

  useEffect(() => {
    fetchDeclarations();
  }, [field, direction, page, pageSize]);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      nonSortableManufacturerHeadings.includes(key) ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            const sortKey = modifyDeclarationsSortKey(key);
            setDirection(
              sortKey !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(sortKey);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  return (
    <>
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex align-items-center justify-content-between w-25 mb-10">
              <Input
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
                label="Year"
                inputWrapperStyle={{
                  padding: "0px 15px",
                }}
              />
              <Input
                value={quarter}
                onChange={(e) => setQuarter(e.target.value)}
                label="Quarter"
                inputWrapperStyle={{
                  padding: "0px 15px",
                  marginLeft: "10px",
                }}
              />
              <ListButton
                label="Filter"
                size="large"
                boxShadow={false}
                className="ml-5"
                onClick={fetchDeclarations}
                disabled={loading}
              />
            </div>
            <ListHeader title="Summary" />
            <Datagrid
              data={data}
              headings={DECLARATIONS_HEADINGS}
              renderRow={renderSummaryRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              isNewTabLink
              loading={loading}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              expandable
              handleExpand={handleExpand}
              expandHeader="Details"
              expandIcon={<ArrowRightAltIcon className="action-icon" />}
            />
          </div>
        </div>
      </div>
    </>
  );
};
