import { getDeclarationStatus } from "../ManufacturerSummary/helpers";

export function modifyDeclaration(declaration = {}) {
  return {
    ...declaration,
    status: getDeclarationStatus(declaration),
    quarter: declaration.instance_number
      ? `${declaration.year} Q${declaration.quarter}/${declaration.instance_number}`
      : `${declaration.year} Q${declaration.quarter}`,
  };
}

export function modifyDeclarationsArray(contacts = []) {
  return contacts.map((item) => modifyDeclaration(item));
}

export const sortByDate = (a, b) => {
  return new Date(b.due_date) - new Date(a.due_date);
};
