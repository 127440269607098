/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import Input from "@bit/the-glue.frontendcomponents.input";
import { Loader } from "../../../../ui/components/Loader";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { useFetch } from "../../../../hooks/fetch.hook";
import { error, info } from "../../../../helpers/toasts";
import {
  setReviewList,
  removeReviewItem,
  updateInvoicedStatus,
  updateReviewList,
} from "./_redux/actions";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import {
  HEADINGS,
  nonSortableManufacturerHeadings,
} from "../ManufacturerSummary/constants";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { ListButton } from "../../../../ui/components/ListButton";
import {
  deleteDeclaration,
  getDeclarationById,
  getDeclarations,
  getDeclarationsAmount,
  invoiceDeclarations,
  reactivateDeclaration,
  splitDeclaration,
} from "../_api";
import { Skeleton } from "@material-ui/lab";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { getSelectedId, sortHelper } from "../../../../ui/helpers";
import { getManufacturersSimple } from "../../Organisations/_api";
import {
  modifyDeclarationsSortKey,
  modifySummary,
  modifySummaryArray,
  renderSummaryRow,
} from "../ManufacturerSummary/helpers";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { exportDeclarationFiles } from "../exportDeclarations";
import { quarterOptions } from "../QuarterlyDeclaration/constants";

export const ManufacturerReview = () => {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const isManufacturerApprover =
    user.permissions?.is_man_approver || user.permissions?.is_manufacturer;

  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selected, setSelected] = useState({});
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [organisations, setOrganisations] = useState([]);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [organisation, setOrganisation] = useState({});
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [unArchiving, setUnarchiving] = useState(false);
  const [filterYear, setFilterYear] = useState("");
  const [quarter, setQuarter] = useState({});

  const data = useSelector(
    ({ manufacturerReview: { reviewList } }) => reviewList
  );

  const fetchItems = () => {
    let searchString = `status=active&status=awaiting_payment&status=draft`;
    searchString += organisation?.value
      ? `&organisation_id=${organisation?.value}`
      : "";
    searchString += quarter?.value ? `&quarter=${quarter?.value}` : "";
    searchString += filterYear ? `&year=_like(${filterYear})_` : "";
    searchString += archived ? "&status=archived" : "";
    setLoading(true);
    request(
      getDeclarations,
      field,
      field && direction,
      page,
      pageSize,
      searchString
    )
      .then((data) => {
        if (!data) return;
        const finalData = modifySummaryArray(data);
        dispatch(setReviewList(finalData));
      })
      .finally(() => {
        setLoading(false);
        setArchivedLoading(false);
      });
  };

  useEffect(() => {
    if (!isManufacturerApprover) return;
    fetchItems();
  }, [page, pageSize, field, direction, archived]);

  useEffect(() => {
    if (!isManufacturerApprover) return;
    let searchString = `status=active&status=awaiting_payment&status=draft`;
    searchString += organisation?.value
      ? `&organisation_id=${organisation?.value}`
      : "";
    searchString += filterYear ? `&year=_like(${filterYear})_` : "";
    searchString += quarter?.value ? `&quarter=${quarter?.value}` : "";
    searchString += archived ? "&status=archived" : "";
    request(getDeclarationsAmount, searchString).then(
      (data) => data && setTotalRecords(data)
    );
  }, [archived]);

  useEffect(() => {
    if (!isManufacturerApprover) return;
    setOrgsLoading(true);
    request(getManufacturersSimple)
      .then((data) => {
        data && setOrganisations(data);
      })
      .finally(() => setOrgsLoading(false));
  }, []);

  const organisationsOptions = useMemo(
    () => [
      ...(organisations || [])
        .map((organiastion) => ({
          value: organiastion.id,
          label: organiastion.business_name,
        }))
        .sort(sortHelper),
    ],
    [organisations]
  );

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    const item = data.find((item) => item.id === String(id));
    if (!item) return;
    if (item.status === "submitted" || item.status === "due") {
      setConfirmDeleteOpen(true);
      setSelectedItem(id);
    } else {
      error("Invoiced items can't be deleted!");
    }
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteDeclaration, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getDeclarationById, id).then(
            (data) => data && dispatch(updateReviewList(modifySummary(data)))
          );
        } else dispatch(removeReviewItem(id));
        info("Item has been archived!");
        setSelectedItem({});
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleInvoice = () => {
    const selectedIds = Object.keys(selected).filter((key) =>
      Boolean(selected[key])
    );
    const payloadIds = selectedIds.map((item) => ({ id: item }));
    const selectedItems =
      data.filter((item) => selectedIds.includes(item.id)) || [];
    const orgID = selectedItems[0]?.organisation_id;
    const sameOrgBelongning = selectedItems.every(
      (item) => item?.organisation_id === orgID
    );
    const properStatus = selectedItems.every(
      (item) => item?.status === "submitted"
    );
    if (!sameOrgBelongning) {
      error("Declarations must belong to the same org!");
    } else if (!properStatus) {
      error("Declarations must have submitted status!");
    } else {
      setActionLoading(true);
      request(invoiceDeclarations, payloadIds)
        .then((data) => {
          if (!data) return;
          info("Declarations have been invoiced!");
          dispatch(updateInvoicedStatus(selectedIds));
        })
        .finally(() => setActionLoading(false));
    }
  };

  const getDefaultValue = () => {
    if (organisation !== null && typeof organisation === "object") {
      return Object.keys(organisation).length ? organisation : null;
    } else return null;
  };

  const handleExpand = (id) => () => {
    const item = data.find((item) => item.id === String(id));
    if (!item) return;
    let url = "";
    if (window.location.hostname === "localhost.dev.agsafe.org.au") {
      url = `https://${window.location.hostname}:${window.location.port}/manufacturers/declaration/${id}`;
    } else {
      url = `https://${window.location.hostname}/manufacturers/declaration/${id}`;
    }
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      nonSortableManufacturerHeadings.includes(key) ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            const sortKey = modifyDeclarationsSortKey(key);
            setDirection(
              sortKey !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(sortKey);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  const invoiceDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 0,
    [selected]
  );

  const handleExport = () => {
    const selectedIDs = Object.keys(selected).filter((key) =>
      Boolean(selected[key])
    );
    const payload = selectedIDs.length
      ? {
          format: "pdf",
          query: {
            id: selectedIDs,
          },
        }
      : {
          format: "pdf",
        };
    setActionLoading(true);
    exportDeclarationFiles(payload, setActionLoading);
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleUnarchive = () => {
    const declarationID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === declarationID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(reactivateDeclaration, declarationID, {})
      .then((data) => {
        if (!data) return;
        dispatch(updateReviewList(modifySummary(data)));
        setSelected({});
        info("Item has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const handleSplit = () => {
    const declarationID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === declarationID) || {}).status;
    if (status !== "invoiced") {
      error("Only invoiced declarations can be split!");
    } else {
      setActionLoading(true);
      request(splitDeclaration, declarationID)
        .then((data) => {
          if (!data) return;
          info("Declaration has been split!");
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  return (
    <>
      {isManufacturerApprover ? (
        <>
          <Loader isOpen={actionLoading} maxWidth="xs" />
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleArchive}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
              />
            }
          />
          <div className="row justify-content-center mt-10">
            <div className="col-12">
              <div className="bg-white rounded py-7 px-10">
                <ListHeader title="Review" />
                <ArchiveCheckbox
                  archivedLoading={archivedLoading}
                  archived={archived}
                  showArchived={showArchived}
                />
                <div className="d-flex mt-10 align-items-center w-50 mb-10">
                  <div className="px-2 mr-5" style={{ minWidth: "280px" }}>
                    {orgsLoading ? (
                      <Skeleton variant="rect" width={"100%"} height={40} />
                    ) : (
                      <Autocomplete
                        size="small"
                        placeholder="Select Organisation"
                        defaultValueField="value"
                        value={organisation}
                        defaultValue={getDefaultValue()}
                        onChange={(event, newValue) => {
                          setOrganisation(newValue);
                        }}
                        options={organisationsOptions}
                        sortable={false}
                        getOptionLabel={(option) => option.label || ""}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        inputStyle={{}}
                      />
                    )}
                  </div>
                  <Input
                    value={filterYear}
                    onChange={(e) => setFilterYear(e.target.value)}
                    label="Year"
                    inputWrapperStyle={{
                      padding: "0px 15px",
                      marginTop: "13px",
                    }}
                  />
                  <div className="px-2 ml-5" style={{ minWidth: "150px" }}>
                    <Autocomplete
                      size="small"
                      placeholder="Quarter"
                      defaultValueField="value"
                      value={quarter}
                      onChange={(event, newValue) => {
                        setQuarter(newValue);
                      }}
                      options={quarterOptions}
                      sortable={false}
                      getOptionLabel={(option) => option.label || ""}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      inputStyle={{}}
                    />
                  </div>
                  <ListButton
                    label="Filter"
                    size="large"
                    boxShadow={false}
                    className="ml-5"
                    onClick={fetchItems}
                    disabled={loading}
                  />
                </div>
                <hr className="mb-5" />
                <div className="d-flex justify-content-end align-items-center mb-10">
                  {archived && (
                    <UnarchiveButton
                      handleClick={handleUnarchive}
                      disabled={unArchiveDisabled}
                      loading={unArchiving}
                    />
                  )}
                  <ListButton
                    label="Split"
                    className="mr-5"
                    size="small"
                    disabled={unArchiveDisabled}
                    onClick={handleSplit}
                  />
                  <ListButton
                    label="Invoice"
                    className="mr-5"
                    size="small"
                    disabled={!invoiceDisabled}
                    onClick={handleInvoice}
                  />
                  <ListButton label="Export" onClick={handleExport} />
                </div>
                <Datagrid
                  data={data}
                  headings={HEADINGS}
                  renderRow={renderSummaryRow}
                  isNewTabLink
                  loading={loading}
                  selectable
                  selected={selected}
                  setSelected={setSelected}
                  handleDelete={handleOpenModal}
                  deletable
                  renderHeaderWithSorting={renderHeaderWithSorting}
                  serverPage={page}
                  setServerPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  isServerPagination
                  totalRecords={totalRecords}
                  expandable
                  handleExpand={handleExpand}
                  expandHeader="Edit"
                  expandIcon={
                    <EditIcon fontSize="small" className="action-icon" />
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white p-10 text-center font-weight-bold">
          You don't have enough permissions to view this page!
        </div>
      )}
    </>
  );
};
