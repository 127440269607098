/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { Loader } from "../../../../ui/components/Loader";
import { Modal } from "../../../../ui/components/Modal";
import { useFetch } from "../../../../hooks/fetch.hook";
import { error, info } from "../../../../helpers/toasts";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { HEADINGS, quarterOptions, yearOptions } from "./constants";
import {
  modifyProduct,
  renderDeclarationsRow,
  renderTotalLevyDue,
} from "./helpers";
import { DeclarationProductForm } from "./DeclarationProductForm";
import { ListButton } from "../../../../ui/components/ListButton";
import { Checkbox } from "../../../../ui/components/Checkbox";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { Formik } from "formik";
import { NewDeclarationSchema } from "../../../../helpers/schemas";
import { addDeclaration } from "../_api";
import { Skeleton } from "@material-ui/lab";
import { ContactSelect } from "./ContactSelect";
import { getManufacturersSimple } from "../../Organisations/_api";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { sortHelper } from "../../../../ui/helpers";
import { setID } from "../../DrumMuster/Inspections/helpers";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";

export const NewQuarterlyDeclaration = () => {
  const { request } = useFetch();

  const [actionLoading, setActionLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [productFormOpen, setProductFormOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);
  const [nilDiclared, setNilDiclared] = useState(false);
  const [organisation, setOrganisation] = useState({});
  const [organisations, setOrganisations] = useState([]);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [products, setProducts] = useState([]);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    setOrgsLoading(true);
    request(getManufacturersSimple)
      .then((data) => {
        if (!data) return;
        setOrganisations(data);
      })
      .finally(() => setOrgsLoading(false));
  }, []);

  const organisationsOptions = useMemo(
    () => [
      ...(organisations || [])
        .map((organiastion) => ({
          value: organiastion.id,
          label: organiastion.business_name,
        }))
        .sort(sortHelper),
    ],
    [organisations]
  );

  const handleProductFormOpen = () => {
    if (!Object.keys(organisation).length) {
      error("Please select organisation first!");
      return;
    }
    setProductFormOpen(true);
  };
  const handleProductFormClose = () => {
    setSelectedItem("");
    setProductFormOpen(false);
  };
  const deleteProduct = (id) => {
    setProducts((state) => {
      return [...state.filter((item) => item.id !== id)];
    });
  };

  const handleExpand = (id) => () => {
    setSelectedItem(id);
    setEditMode(true);
    handleProductFormOpen();
  };

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleSubmitChange = () => {
    setIsSubmitted(!isSubmitted);
  };

  const handleDeclaredChange = () => {
    setNilDiclared(!nilDiclared);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (!products.length && !nilDiclared) {
      error("At least one product required");
      setSubmitting(false);
      return;
    }
    if (products.length && nilDiclared) {
      error("Nil Declared item can't have products!");
      setSubmitting(false);
      return;
    }

    const payload = {
      contact_id: values.contact_id,
      quarter: Number(values.quarter),
      year: values.year,
      status: (isSubmitted || nilDiclared) && "active",
      declaration_products: products.map((product) => {
        return {
          material: product.material,
          unit_type: "Kg",
          size: product.size,
          quantity: product.quantity,
          price: Number(product.price),
          nsw_percent: product.nsw_percent,
          nt_percent: product.nt_percent,
          tas_percent: product.tas_percent,
          qld_percent: product.qld_percent,
          wa_percent: product.wa_percent,
          sa_percent: product.sa_percent,
          act_percent: product.act_percent,
          vic_percent: product.vic_percent,
        };
      }),
      organisation_id: values.organisation_id,
      purchase_order_number: values.purchase_order_number,
    };

    if (!nilDiclared && !isSubmitted) {
      delete payload.status;
    }

    setActionLoading(true);
    request(addDeclaration, payload)
      .then((data) => {
        if (!data) return;
        setNilDiclared(false);
        setIsAgreed(false);
        setIsSubmitted(false);
        setProducts([]);
        setOrganisation("");
        setResetKey(Date.now());
        info("Declaration has been submiteed!");
      })
      .finally(() => {
        setActionLoading(false);
        setSubmitting(false);
      });
  };

  const addNewProduct = (product, editMode, id) => {
    if (editMode) {
      const index = products.findIndex((item) => item?.id === id);
      setProducts((state) => {
        return [
          ...state.slice(0, index),
          modifyProduct({ ...product, id }),
          ...state.slice(index + 1),
        ];
      });
    } else {
      setProducts((oldData) => [
        ...oldData,
        { ...modifyProduct(product), id: setID(10000000, oldData) },
      ]);
    }
  };

  const initialValues = {
    organisation_id: "",
    year: "2023-2024",
    quarter: "",
    contact_id: "",
    date_entered: new Date(Date.now()),
    purchase_order_number: "",
  };

  const renderActionContent = (isSubmitting, handleSubmit) => (
    <>
      <div className="d-flex justify-content-center">
        <ListButton
          label="Add Product"
          size="large"
          boxShadow={false}
          onClick={() => {
            handleProductFormOpen();
            setEditMode(false);
          }}
        />
      </div>
      <div className="d-flex align-items-center mt-20">
        <div>
          <Checkbox
            checked={isAgreed}
            onChange={() => handleAgreementChange()}
          />
        </div>
        <div>
          I am authorised by the Reporting Organisation to prepare this report
          on its behalf. <br /> The information contained in this report is true
          and correct to the best of my information, knowledge, and belief
          (accidental errors and omissions excepted) <br /> and has been
          prepared with due regard to the books and records of the Reporting
          Organisation.
        </div>
      </div>
      <div className="d-flex align-items-center mt-10">
        <div>
          <Checkbox
            checked={isSubmitted}
            onChange={() => handleSubmitChange()}
          />
        </div>
        <div className="mb-2">Submit declaration for review on creation</div>
      </div>
      <div className="mt-20 d-flex justify-content-end">
        <ListButton
          label="Submit"
          size="large"
          onClick={handleSubmit}
          boxShadow={false}
          disabled={!isAgreed || isSubmitting}
        />
      </div>
    </>
  );

  const getDeclarationData = () => {
    const selectedOrg = organisations.find(
      (org) => org?.id === organisation?.value
    );
    if (!selectedOrg) return;
    return {
      ...organisation,
      organisation: { ...selectedOrg },
    };
  };

  return (
    <>
      <Loader isOpen={actionLoading} maxWidth="xs" />
      <Modal
        isOpen={productFormOpen}
        submitable
        onClose={handleProductFormClose}
        maxWidth="md"
        modalContent={
          <DeclarationProductForm
            handleClose={handleProductFormClose}
            editMode={editMode}
            data={products.find(({ id }) => id === selectedItem)}
            declarationData={getDeclarationData()}
            newDeclaration={true}
            addNewProduct={addNewProduct}
          />
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={NewDeclarationSchema}
        onSubmit={handleSubmit}
        key={resetKey}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
          <div className="row justify-content-center mt-10">
            <div className="col-12">
              <div className="bg-white rounded py-7 px-10">
                <h3 className="mb-10">
                  <strong>Quarterly Declaration</strong>
                </h3>
                <div>
                  <div className="row justify-content-between">
                    <div className="col-3">
                      {orgsLoading ? (
                        <Skeleton variant="rect" width={"100%"} height={40} />
                      ) : (
                        <Autocomplete
                          size="small"
                          placeholder="Select Organisation"
                          defaultValueField="value"
                          value={organisation}
                          key={resetKey}
                          onChange={(event, newValue) => {
                            setOrganisation(newValue);
                            setFieldValue("organisation_id", newValue?.value);
                          }}
                          options={organisationsOptions}
                          sortable={false}
                          getOptionLabel={(option) => option.label || ""}
                          getOptionSelected={(option, value) =>
                            option.value === value.value
                          }
                          inputStyle={{}}
                        />
                      )}
                    </div>
                    <div>
                      <MappedSelect
                        label="Financial Year"
                        name="year"
                        options={yearOptions}
                      />
                    </div>
                    <MappedSelect
                      name="quarter"
                      label="Quarter"
                      options={quarterOptions}
                    />
                    <ContactSelect />
                    <MappedDatePicker
                      name="date_entered"
                      label="Date entered"
                      shouldCloseOnSelect
                      disabled
                    />
                    <div className="">
                      <MappedInput
                        name="purchase_order_number"
                        label="Purchase Order No. (optional)"
                      />
                    </div>
                  </div>
                </div>
                <div className="my-5 d-flex justify-content-between align-items-center">
                  <Checkbox
                    checked={nilDiclared}
                    label="Nil Declared"
                    onChange={() => handleDeclaredChange()}
                  />
                </div>
                <ListHeader title="My Products" />
                <div className="mt-10">
                  <Datagrid
                    data={products}
                    headings={HEADINGS}
                    renderRow={renderDeclarationsRow}
                    isNewTabLink
                    handleDelete={deleteProduct}
                    deletable
                    paginationOptions={[50, 100, 200]}
                    paginationDefaultValue={50}
                    expandable
                    handleExpand={handleExpand}
                    expandHeader="Edit"
                    footerRow={(data) => renderTotalLevyDue(data)}
                  />
                </div>

                {renderActionContent(isSubmitting, handleSubmit)}
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
