import React, { useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { Skeleton } from "@material-ui/lab";
import { getOrganisationContacts } from "../../Organisations/_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { sortHelper } from "../../../../ui/helpers";

export const ContactSelect = () => {
  const { request } = useFetch();
  const { values, setFieldValue, ...formik } = useFormikContext();

  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);

  useEffect(() => {
    if (!values.organisation_id) return;
    setContactsLoading(true);
    request(getOrganisationContacts, values.organisation_id, ...Array(5), 50)
      .then((data) => data && setContacts(data))
      .finally(() => setContactsLoading(false));
    // eslint-disable-next-line
  }, [values.organisation_id]);

  const contactOptions = useMemo(
    () => [
      ...(contacts || [])
        .map((contact) => ({
          value: contact.id,
          label: [contact.first_name, contact.last_name].join(" "),
        }))
        .sort(sortHelper),
    ],
    [contacts]
  );

  return (
    <div className="col-2">
      {contactsLoading ? (
        <Skeleton variant="rect" width={"100%"} height={40} />
      ) : (
        <>
          <Autocomplete
            name="contact_id"
            placeholder="Contact"
            setValue={setFieldValue}
            options={contactOptions}
            defaultValueField="value"
            defaultValue={values.contact_id}
          />
          {formik.touched.contact_id && formik.errors.contact_id ? (
            <div className="text-danger">{formik.errors.contact_id}</div>
          ) : null}
        </>
      )}
    </div>
  );
};
