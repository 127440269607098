import { summaryTypes } from "./actions";

const initialState = {
  summaryList: [],
};

export function manufacturerSummaryReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case summaryTypes.SET_DATA:
      return {
        ...state,
        summaryList: payload,
      };

    case summaryTypes.REMOVE_DATA:
      return {
        ...state,
        summaryList: [...state.summaryList.filter(({ id }) => id !== payload)],
      };

    case summaryTypes.UPDATE_DATA:
      const index = state.summaryList.findIndex(
        (item) => (item || {}).id === (payload || {}).id
      );
      return {
        ...state,
        summaryList: [
          ...state.summaryList.slice(0, index),
          payload,
          ...state.summaryList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
