import React, { useState } from "react";
import { Formik } from "formik";
import Dropzone from "react-dropzone";
import { ReactComponent as UploadIcon } from "../static/UploadIcon.svg";
import { useFetch } from "../../hooks/fetch.hook";
import { error, info } from "../../helpers/toasts";
import { startUpload } from "../../app/modules/AWS-S3/upload";
import { File } from "./FileUploadForm/File";
import { modifyEvidenceFile } from "../../app/modules/Manufacturers/_api";
import { DialogContent, LinearProgress, Dialog } from "@material-ui/core";
import { ListButton } from "../components/ListButton";

export const SingleStepFileUpload = ({
  handleClose,
  apiCall,
  extraPayload = {},
  id,
  isOpen,
}) => {
  const initialValues = {};
  const { request } = useFetch();
  const [uploadedFile, setUploadedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState("");
  const [fileSubmitted, setFileSubmitted] = useState(false);

  const onClose = () => {
    setUploadedFile("");
    handleClose();
  };

  const submitFileName = () => {
    return request(apiCall, id, {
      file_name: uploadedFile.name,
      ...extraPayload,
    })
      .then((data) => {
        if (!data) return;
        setFileData(data);
        uploadFile(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpload = (values, { setSubmitting }) => {
    setLoading(true);
    request(modifyEvidenceFile, id, fileData.id, {})
      .then((data) => {
        if (!data) return;
        info("File has been uploaded!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
        handleClose();
      });
  };

  const uploadFile = async (data = {}) => {
    const payload = {
      url: data.aws_s3_presigned_package.url,
      fields: {
        ...data.aws_s3_presigned_package.fields,
        file: uploadedFile,
      },
    };
    await startUpload(data.file_name, payload).catch((err) => {
      error((err || {}).message);
    });
    setFileSubmitted(true);
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent className="p-10">
        <Formik initialValues={initialValues} onSubmit={handleUpload}>
          {({ handleSubmit, isSubmitting }) => (
            <div>
              <div className="text-center">
                <h3 className="mb-5">Upload Your File</h3>

                {uploadedFile ? (
                  <div className="mt-5">
                    <File file={uploadedFile} uploadFile={submitFileName} />
                  </div>
                ) : null}
                <div>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      setUploadedFile((acceptedFiles || [])[0])
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            padding: 60,
                            borderWidth: 2,
                            borderRadius: 2,
                            borderColor: "#64A70B",
                            borderStyle: "solid",
                            backgroundColor: "#ffffff",
                            color: "#828282",
                            outline: "none",
                            transition: "border .24s ease-in-out",
                            cursor: "pointer",
                          }}
                        >
                          <input {...getInputProps()} />
                          <p className="mb-7">
                            <UploadIcon />
                          </p>
                          <p style={{ fontSize: "16px" }}>
                            Drag File on to this zone or click to browse for the
                            PDF
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-10">
                <ListButton
                  label="Cancel"
                  boxShadow={false}
                  onClick={onClose}
                  size="medium"
                  className="mr-3"
                  variant="outlined"
                  text="#407A28"
                  disabled={isSubmitting || loading}
                />
                <ListButton
                  label="Submit"
                  size="medium"
                  boxShadow={false}
                  onClick={handleSubmit}
                  disabled={
                    isSubmitting || !uploadedFile || loading || !fileSubmitted
                  }
                />
              </div>
              <div className="py-2">{loading && <LinearProgress />}</div>
            </div>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
