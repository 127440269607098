import config from "react-global-configuration";

export const invoiceDeclarations = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/invoices`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const downloadSummary = (invoice_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/declarations/invoices/${invoice_id}/download`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const exportDeclarations = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/export`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getDeclarations = (
  field,
  direction,
  page = 0,
  page_size = 10,
  search = ""
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/declarations?${search && "&" + search}${
      (field && "&order_by=" + field) || ""
    }${
      (direction && "&order_dir=" + direction) || ""
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getDeclarationsAmount = (search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/declarations?count_only=y${
      search && "&" + search
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const addDeclaration = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getDeclarationProducts = (
  declaration_id,
  field,
  direction,
  page = 0,
  page_size = 10
) => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/declarations/${declaration_id}/declaration_products?${
      (field && "&order_by=" + field) || ""
    }${
      (direction && "&order_dir=" + direction) || ""
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getDeclarationProductsAmount = (declaration_id) => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/declarations/${declaration_id}/declaration_products?count_only=y`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const addDeclarationProduct = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/declaration_products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editDeclaration = (data, id) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editDeclarationProduct = (data, id) =>
  fetch(`${config.get("ApiUrl").Rest}/declaration_products/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getDeclarationById = (id) => {
  return fetch(`${config.get("ApiUrl").Rest}/declarations/${id}`, {
    mode: "cors",
    credentials: "include",
  });
};

export const deleteDeclarationProduct = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/declaration_products/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const deleteDeclaration = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const createEvidenceFile = (declaration_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/${declaration_id}/files`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const modifyEvidenceFile = (declaration_id, file_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/declarations/${declaration_id}/files/${file_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const reactivateDeclaration = (id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/${id}/reactivate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const splitDeclaration = (declaration_id) =>
  fetch(`${config.get("ApiUrl").Rest}/declarations/${declaration_id}/split`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });
