import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Loader } from "../../../../ui/components/Loader";
import ReportIcon from "../../../../ui/static/Report.png";
import { error, info } from "../../../../helpers/toasts";
import { get } from "lodash";

export const SummaryDownload = ({ item = {} }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    if (!item.invoice_id) {
      info("invoice not found!");
      return;
    }
    setLoading(true);
    try {
      fetch(
        `https://api.dev.agsafe.org.au/declarations/invoices/${item.invoice_id}/download`,
        {
          mode: "cors",
          credentials: "include",
        }
      )
        .then((data) => data.text())
        .then((data) => {
          const downloadUrl = "data:application/octet-stream;base64," + data;
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = `${get(item, "invoice.code", "invoice")}.pdf`;
          link.setAttribute("target", "_blank");
          link.setAttribute("rel", "noopener noreferrer");
          link.click();
        })
        .finally(() => setLoading(false));
    } catch {
      error("Something went wrong!");
    }
  };

  const handleModalClose = () => setLoading(false);
  return (
    <>
      {loading && (
        <Loader
          title="Downloading PDF..."
          onClose={handleModalClose}
          isOpen={loading}
        />
      )}
      <Button
        data-testid="download"
        onClick={handleDownload}
        disabled={!item.invoice_id}
      >
        <span className="cursor-pointer">
          <img
            src={ReportIcon}
            alt="Report"
            style={{ opacity: item.invoice_id ? 1 : 0.6 }}
          />
        </span>
      </Button>
    </>
  );
};
