import React, { useState } from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { productTypeOptions } from "./constants";
import { TotalAmount } from "./TotalAmount";
import { ListButton } from "../../../../ui/components/ListButton";
import { useDispatch } from "react-redux";
import { addProduct, updateProductsList } from "./_redux/actions";
import { DeclarationProductSchema } from "../../../../helpers/schemas";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addDeclarationProduct, editDeclarationProduct } from "../_api";
import { LinearProgress } from "@material-ui/core";
import { modifyProduct } from "./helpers";
import { get } from "lodash";

export const DeclarationProductForm = ({
  handleClose,
  data = {},
  editMode,
  declarationId,
  declarationData = {},
  newDeclaration = false,
  addNewProduct,
}) => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [matches, setMatches] = useState(false);

  const initialValues = {
    material: data.material || "",
    size: data.size || "",
    quantity: data.quantity || "",
    price:
      data.price ||
      get(declarationData, "organisation.manufacturer_unit_price", "0.06"),
    nsw_percent: data.nsw_percent || "",
    nt_percent: data.nt_percent || "",
    tas_percent: data.tas_percent || "",
    qld_percent: data.qld_percent || "",
    wa_percent: data.wa_percent || "",
    sa_percent: data.sa_percent || "",
    act_percent: data.act_percent || "",
    vic_percent: data.vic_percent || "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (newDeclaration) {
      addNewProduct(
        {
          ...values,
          size: Number(values.size),
          quantity: Number(values.quantity),
          unit_price: Number(values.price),
          nsw_percent: Number(values.nsw_percent),
          nt_percent: Number(values.nt_percent),
          tas_percent: Number(values.tas_percent),
          qld_percent: Number(values.qld_percent),
          wa_percent: Number(values.wa_percent),
          sa_percent: Number(values.sa_percent),
          act_percent: Number(values.act_percent),
          vic_percent: Number(values.vic_percent),
        },
        editMode,
        data?.id
      );
      setSubmitting(false);
      handleClose();
      return;
    }
    setLoading(true);
    const payload = {
      ...values,
      size: Number(values.size),
      nsw_percent: Number(values.nsw_percent),
      nt_percent: Number(values.nt_percent),
      tas_percent: Number(values.tas_percent),
      qld_percent: Number(values.qld_percent),
      wa_percent: Number(values.wa_percent),
      sa_percent: Number(values.sa_percent),
      act_percent: Number(values.act_percent),
      vic_percent: Number(values.vic_percent),
      declaration_id: declarationId,
      unit_type: "Kg",
    };
    request(
      editMode ? editDeclarationProduct : addDeclarationProduct,
      payload,
      data?.id
    )
      .then((data) => {
        if (!data) return;
        const modifiedData = modifyProduct(data);
        dispatch(
          editMode ? updateProductsList(modifiedData) : addProduct(modifiedData)
        );
        handleClose();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={DeclarationProductSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="px-10 py-5">
          <div className="row">
            <div className="col-5 mr-20">
              <h3 className="mb-15">
                <strong>{editMode ? "Edit Product" : "Add Product"}</strong>
              </h3>
            </div>
            <div className="col-6">
              <h4>
                <strong>Sales Percentage by State</strong>
              </h4>
              <p className="mb-1">Has to add up to 100%</p>
              <TotalAmount matches={matches} setMatches={setMatches} />
            </div>
          </div>
          <div className="row">
            <div className="col-5 mr-20">
              <div className="mb-5">
                <MappedSelect
                  label="Product Type"
                  options={productTypeOptions}
                  name="material"
                  data-testid="material"
                />
              </div>
              <div className="mb-5">
                <MappedInput label="Size L/Kg" name="size" data-testid="size" />
              </div>
              <div className="mb-5">
                <MappedInput name="quantity" label="Quantity" />
              </div>
              <div className="mb-5">
                <MappedInput name="price" label="Price Per Unit" disabled />
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="mb-5">
                    <MappedInput name="nsw_percent" label="New South Wales" />
                  </div>
                  <div className="mb-5">
                    <MappedInput name="vic_percent" label="Victoria" />
                  </div>
                  <div className="mb-5">
                    <MappedInput name="nt_percent" label="Northern Territory" />
                  </div>
                  <div className="mb-5">
                    <MappedInput name="tas_percent" label="Tasmania" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-5">
                    <MappedInput name="qld_percent" label="Queensland" />
                  </div>
                  <div className="mb-5">
                    <MappedInput name="wa_percent" label="Western Australia" />
                  </div>
                  <div className="mb-5">
                    <MappedInput name="sa_percent" label="South Australia" />
                  </div>
                  <div className="mb-5">
                    <MappedInput
                      name="act_percent"
                      label="Australian Capital Ter."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="my-5 d-flex justify-content-end">
              <div className="mr-3">
                <ListButton
                  label="Cancel"
                  size="large"
                  onClick={handleClose}
                  variant="outlined"
                  text="#407A28"
                  boxShadow={false}
                />
              </div>
              <div>
                <ListButton
                  label="Submit"
                  size="large"
                  boxShadow={false}
                  onClick={handleSubmit}
                  disabled={isSubmitting || loading || !matches}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="mt-5">
              <LinearProgress color="secondary" />
            </div>
          ) : null}
        </div>
      )}
    </Formik>
  );
};
