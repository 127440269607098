export const HEADINGS = [
  ["organisation_name", "Organisation"],
  ["quarter", "Quarter"],
  ["submitted_at", "Date Submitted"],
  ["submitted_by", "Submitted by"],
  ["total_qty", "Total QTY"],
  ["total_due", "Total due (incl. GST)"],
  ["status", "Status"],
  ["download", "Download"],
];

export const nonSortableManufacturerHeadings = [
  "total_qty",
  "download",
  "quarter",
];
