/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { Loader } from "../../../../ui/components/Loader";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { useFetch } from "../../../../hooks/fetch.hook";
import { error, info } from "../../../../helpers/toasts";
import { setProductsList, removeProduct } from "./_redux/actions";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import {
  HEADINGS,
  editableStatusesAdmin,
  editableStatusesUser,
  quarterOptions,
  yearOptions,
} from "./constants";
import {
  modifyProductsArray,
  renderDeclarationsRow,
  renderTotalLevyDue,
} from "./helpers";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { DeclarationProductForm } from "./DeclarationProductForm";
import { ListButton } from "../../../../ui/components/ListButton";
import { Checkbox } from "../../../../ui/components/Checkbox";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { Formik } from "formik";
import { DeclarationSchema } from "../../../../helpers/schemas";
import {
  createEvidenceFile,
  deleteDeclarationProduct,
  editDeclaration,
  getDeclarationById,
  getDeclarationProducts,
  getDeclarationProductsAmount,
} from "../_api";
import { Skeleton } from "@material-ui/lab";
import { getOrganisationById } from "../../Organisations/_api";
import { ContactSelect } from "./ContactSelect";
import Input from "@bit/the-glue.frontendcomponents.input";
import { SingleStepFileUpload } from "../../../../ui/structures/SingleStepFileUpload";
import { AccessControl } from "../../../../ui/structures/AccessControl";
import { modifySummary } from "../ManufacturerSummary/helpers";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";

export const QuarterlyDeclaration = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [productFormOpen, setProductFormOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);
  const [nilDiclared, setNilDiclared] = useState(false);
  const [declarationData, setDeclarationData] = useState({});
  const [declarationLoading, setDeclarationLoading] = useState(false);
  const [organisation, setOrganisation] = useState([]);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [fileFormOpen, setFileFormOpen] = useState(false);

  const handleFileFormClose = () => setFileFormOpen(false);
  const handleOpenForm = () => setFileFormOpen(true);

  const handleProductFormOpen = () => setProductFormOpen(true);
  const handleProductFormClose = () => {
    setSelectedItem("");
    setProductFormOpen(false);
  };

  const data = useSelector(
    ({ quarterlyDeclaration: { declarationList } }) => declarationList
  );

  const fetchProducts = () => {
    setLoading(true);
    request(
      getDeclarationProducts,
      id,
      field,
      field && direction,
      page,
      pageSize
    ).then((data) => {
      if (!data) return;
      dispatch(setProductsList(modifyProductsArray(data)));
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, [page, pageSize, field, direction]);

  useEffect(() => {
    request(getDeclarationProductsAmount, id).then(
      (data) => data && setTotalRecords(data)
    );
  }, []);

  useEffect(() => {
    if (!declarationData?.organisation_id) return;
    setOrgsLoading(true);
    request(getOrganisationById, declarationData?.organisation_id)
      .then((data) => data && setOrganisation(data))
      .finally(() => setOrgsLoading(false));
  }, [declarationData]);

  useEffect(() => {
    setDeclarationLoading(true);
    request(getDeclarationById, id)
      .then((data) => {
        if (!data) return;
        setDeclarationData(modifySummary(data));
        setNilDiclared(data?.status === "nil_declared" ? true : false);
      })
      .finally(() => setDeclarationLoading(false));
  }, []);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    if (
      declarationData.status === "invoiced" ||
      declarationData.status === "paid"
    ) {
      error("Invoiced declaration's products can't be deleted!");
      return;
    } else {
      setConfirmDeleteOpen(true);
      setSelectedItem(id);
    }
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteDeclarationProduct, id)
      .then((data) => {
        if (!data) return;
        dispatch(removeProduct(id));
        info("Item has been archived!");
        setSelectedItem({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const handleExpand = (id) => () => {
    if (
      declarationData.status === "invoiced" ||
      declarationData.status === "paid"
    ) {
      error("Invoiced declaration's products can't be edited!");
      return;
    } else {
      setSelectedItem(id);
      setEditMode(true);
      handleProductFormOpen();
    }
  };

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleDeclaredChange = () => {
    setNilDiclared(!nilDiclared);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (!data.length && !nilDiclared) {
      error("At least one product required");
      setSubmitting(false);
      return;
    }
    if (data.length && nilDiclared) {
      error("Nil Declared item can't have products!");
      setSubmitting(false);
      return;
    }
    const payload = {
      contact_id: values.contact_id,
      status: "active",
      purchase_order_number: values.purchase_order_number,
    };
    setActionLoading(true);
    request(editDeclaration, payload, id)
      .then((data) => data && info("Success!"))
      .finally(() => {
        setActionLoading(false);
        setSubmitting(false);
      });
  };

  if (declarationLoading) {
    return <Skeleton variant="rect" width={"100%"} height={500} />;
  }

  const initialValues = {
    organisation_id: declarationData?.organisation_id || "",
    year: declarationData?.year || "2023-2024",
    quarter: declarationData?.real_quarter || "",
    contact_id: declarationData?.contact_id || "",
    date_entered: new Date(Date.now()),
    purchase_order_number: declarationData?.purchase_order_number || "",
  };

  const renderActionContent = (isSubmitting, handleSubmit) => (
    <>
      <div className="d-flex justify-content-center">
        <ListButton
          label="Add Product"
          size="large"
          boxShadow={false}
          disabled={declarationData?.status === "nil_declared"}
          onClick={() => {
            handleProductFormOpen();
            setEditMode(false);
          }}
        />
      </div>
      <div className="d-flex align-items-center mt-20">
        <div>
          <Checkbox
            checked={isAgreed}
            onChange={() => handleAgreementChange()}
          />
        </div>
        <div>
          I am authorised by the Reporting Organisation to prepare this report
          on its behalf. <br /> The information contained in this report is true
          and correct to the best of my information, knowledge, and belief
          (accidental errors and omissions excepted) <br /> and has been
          prepared with due regard to the books and records of the Reporting
          Organisation.
        </div>
      </div>
      <div className="mt-20 d-flex justify-content-end">
        <ListButton
          label="Submit"
          size="large"
          onClick={handleSubmit}
          boxShadow={false}
          disabled={!isAgreed || isSubmitting}
        />
      </div>
    </>
  );

  const createDownloadLink = (downloadUrl) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
  };

  const downloadEvidence = () => {
    const evidenceUrl = declarationData.evidence_url;
    if (evidenceUrl) {
      createDownloadLink(evidenceUrl);
    } else {
      error("Evidence not found!");
    }
  };

  return (
    <>
      <Loader isOpen={actionLoading} maxWidth="xs" />
      <Modal
        isOpen={confirmDeleteOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="sm"
        modalContent={
          <ConfirmDelete
            handleClose={handleModalClose}
            handleSubmit={handleArchive}
            id={selectedItem}
          />
        }
      />
      <Modal
        isOpen={productFormOpen}
        submitable
        onClose={handleProductFormClose}
        maxWidth="md"
        modalContent={
          <DeclarationProductForm
            handleClose={handleProductFormClose}
            editMode={editMode}
            data={data.find(({ id }) => id === selectedItem)}
            declarationId={id}
            declarationData={declarationData}
          />
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={DeclarationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
          <div className="row justify-content-center mt-10">
            {fileFormOpen && (
              <SingleStepFileUpload
                handleClose={handleFileFormClose}
                isOpen={fileFormOpen}
                id={declarationData.id}
                apiCall={createEvidenceFile}
              />
            )}
            <div className="col-12">
              <div className="bg-white rounded py-7 px-10">
                <h3 className="mb-10">
                  <strong>Quarterly Declaration</strong>
                </h3>
                <div>
                  {declarationLoading ? (
                    <Skeleton variant="rect" width={"100%"} height={70} />
                  ) : (
                    <div className="row justify-content-between">
                      <div className="col-3">
                        {orgsLoading ? (
                          <Skeleton variant="rect" width={"100%"} height={40} />
                        ) : (
                          <Input
                            label="Organisation Name"
                            value={organisation?.business_name}
                            disabled
                          />
                        )}
                      </div>
                      <div className="col-2">
                        <MappedSelect
                          label="Financial Year"
                          name="year"
                          options={yearOptions}
                          disabled={
                            !editableStatusesAdmin.includes(
                              declarationData?.status
                            )
                          }
                        />
                      </div>
                      <MappedSelect
                        name="quarter"
                        label="Quarter"
                        options={quarterOptions}
                        disabled={
                          !editableStatusesAdmin.includes(
                            declarationData?.status
                          )
                        }
                      />
                      <ContactSelect />
                      <MappedDatePicker
                        name="date_entered"
                        label="Date entered"
                        shouldCloseOnSelect
                        disabled
                      />
                      <div className="col-2">
                        <MappedInput
                          name="purchase_order_number"
                          label="Purchase Order No. (optional)"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="my-5 d-flex justify-content-between align-items-center">
                  <Checkbox
                    checked={nilDiclared}
                    label="Nil Declared"
                    onChange={() => handleDeclaredChange()}
                    disabled={declarationData?.status === "nil_declared"}
                  />
                  <div className="d-flex">
                    {(declarationData?.status === "submitted" ||
                      declarationData?.status === "invoiced" ||
                      declarationData?.status === "paid") && (
                      <ListButton
                        label="Download Evidence"
                        size="small"
                        className="px-10 mr-5"
                        onClick={downloadEvidence}
                        disabled={!declarationData.evidence_url}
                      />
                    )}
                    {editableStatusesAdmin.includes(
                      declarationData?.status
                    ) && (
                      <AccessControl
                        desiredRoles={[
                          AVAILABLE_ROLES.NATIONAL_ADMIN,
                          AVAILABLE_ROLES.AGSAFE_ADMIN,
                          AVAILABLE_ROLES.AGSAFE_STAFF,
                        ]}
                      >
                        <ListButton
                          label={`${
                            declarationData.evidence_url
                              ? "Re-upload Evidence"
                              : "Upload Evidence"
                          }`}
                          size="small"
                          className="px-10"
                          onClick={handleOpenForm}
                        />
                      </AccessControl>
                    )}
                  </div>
                </div>
                <ListHeader title="My Products" />
                <div className="mt-10">
                  <AccessControl
                    desiredRoles={[
                      AVAILABLE_ROLES.NATIONAL_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_STAFF,
                    ]}
                    elseContent={
                      <>
                        {editableStatusesUser.includes(
                          declarationData?.status
                        ) ? (
                          <Datagrid
                            data={data}
                            headings={HEADINGS}
                            renderRow={renderDeclarationsRow}
                            isNewTabLink
                            loading={loading}
                            handleDelete={handleOpenModal}
                            deletable
                            renderHeaderWithSorting={renderHeaderWithSorting}
                            serverPage={page}
                            setServerPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            isServerPagination
                            totalRecords={totalRecords}
                            expandable
                            handleExpand={handleExpand}
                            expandHeader="Edit"
                            footerRow={(data) => renderTotalLevyDue(data)}
                          />
                        ) : (
                          <Datagrid
                            data={data}
                            headings={HEADINGS}
                            renderRow={renderDeclarationsRow}
                            isNewTabLink
                            loading={loading}
                            renderHeaderWithSorting={renderHeaderWithSorting}
                            serverPage={page}
                            setServerPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            isServerPagination
                            totalRecords={totalRecords}
                            footerRow={(data) => renderTotalLevyDue(data)}
                          />
                        )}
                      </>
                    }
                  >
                    {editableStatusesAdmin.includes(declarationData?.status) ? (
                      <Datagrid
                        data={data}
                        headings={HEADINGS}
                        renderRow={renderDeclarationsRow}
                        isNewTabLink
                        loading={loading}
                        handleDelete={handleOpenModal}
                        deletable
                        renderHeaderWithSorting={renderHeaderWithSorting}
                        serverPage={page}
                        setServerPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        isServerPagination
                        totalRecords={totalRecords}
                        expandable
                        handleExpand={handleExpand}
                        expandHeader="Edit"
                        footerRow={(data) => renderTotalLevyDue(data)}
                      />
                    ) : (
                      <Datagrid
                        data={data}
                        headings={HEADINGS}
                        renderRow={renderDeclarationsRow}
                        isNewTabLink
                        loading={loading}
                        renderHeaderWithSorting={renderHeaderWithSorting}
                        serverPage={page}
                        setServerPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        isServerPagination
                        totalRecords={totalRecords}
                        footerRow={(data) => renderTotalLevyDue(data)}
                      />
                    )}
                  </AccessControl>
                </div>

                <AccessControl
                  desiredRoles={[
                    AVAILABLE_ROLES.NATIONAL_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_STAFF,
                  ]}
                  elseContent={
                    <>
                      {editableStatusesUser.includes(declarationData?.status) &&
                        renderActionContent(isSubmitting, handleSubmit)}
                    </>
                  }
                >
                  {editableStatusesAdmin.includes(declarationData?.status) &&
                    renderActionContent(isSubmitting, handleSubmit)}
                </AccessControl>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
