export const declarationTypes = {
  SET_DATA: "DECLARATION/SET_DATA",
  REMOVE_DATA: "DECLARATION/REMOVE_DATA",
  UPDATE_DATA: "DECLARATION/UPDATE_DATA",
  ADD_ITEM: "DECLARATION/ADD_ITEM",
};

export function setProductsList(data) {
  return {
    type: declarationTypes.SET_DATA,
    payload: data,
  };
}

export function addProduct(data) {
  return {
    type: declarationTypes.ADD_ITEM,
    payload: data,
  };
}

export function removeProduct(data) {
  return {
    type: declarationTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateProductsList(data) {
  return {
    type: declarationTypes.UPDATE_DATA,
    payload: data,
  };
}
