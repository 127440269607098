/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { format, subDays } from "date-fns";
import { Link } from "react-router-dom";
import { DeclarationItem } from "./DeclarationItem";
import { Calendar } from "../../Dashboard/Calendar";
import { ReactComponent as CurrentTasksIcon } from "../../../../ui/static/CurrentTasksIcon.svg";
import { ReactComponent as OverdueTasksIcon } from "../../../../ui/static/OverdueTasksIcon.svg";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getDeclarations } from "../_api";
import { modifyDeclarationsArray, sortByDate } from "./helpers";
import { getTasks } from "../../Tasks/_api";
import { useSelector } from "react-redux";

export const tiles = [
  {
    title: "Current Tasks",
    icon: <CurrentTasksIcon />,
    path: "/tasks",
  },
  {
    title: "Overdue Tasks",
    icon: <OverdueTasksIcon />,
    path: "/tasks",
  },
];

export const ManufacturerDashobard = () => {
  const { request } = useFetch();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const isManufacturerApprover =
    user.permissions?.is_man_approver || user.permissions?.is_manufacturer;

  const [currentTasks, setCurrentTasks] = useState("");
  const [overdueTasks, setOverduetasks] = useState("");
  const [declarations, setDeclarations] = useState([]);
  const [loading, setLoading] = useState(false);

  const quantityMap = {
    "Overdue Tasks": overdueTasks,
    "Current Tasks": currentTasks,
  };

  useEffect(() => {
    if (!isManufacturerApprover) return;
    request(
      getTasks,
      `count_only=y&users.id=${
        user.id
      }&completed_date=_null_&gte-due_date=${format(
        subDays(new Date(Date.now()), 1),
        "yyyy/MM/dd"
      )}`
    ).then((data) => {
      if (!data && data !== 0) return;
      setCurrentTasks(data);
    });
    request(
      getTasks,
      `count_only=y&completed_date=_null_&lte-due_date=${format(
        new Date(Date.now()),
        "yyyy/MM/dd"
      )}`
    ).then((data) => {
      if (!data && data !== 0) return;
      setOverduetasks(data);
    });
  }, []);

  useEffect(() => {
    if (!isManufacturerApprover) return;
    let searchQuery = "";
    setLoading(true);
    request(getDeclarations, "due_date", "desc", 0, 10, searchQuery)
      .then((data) => {
        if (!data) return;
        setDeclarations(modifyDeclarationsArray(data.sort(sortByDate)));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {isManufacturerApprover ? (
        <div>
          <div className="row justify-content-between">
            <div className="col-9 pr-15">
              <div className="d-flex">
                {tiles.map(({ title, icon, path }) => (
                  <Link
                    to={{
                      pathname: path,
                    }}
                    key={title}
                  >
                    <div className="py-7 text-center tile-wrapper mr-15 border rounded">
                      <div className="mb-5">{icon}</div>
                      <div
                        style={{ fontSize: "2rem", color: "#828282" }}
                        className="mb-3 text-center"
                      >
                        {quantityMap[title] || quantityMap[title] === 0 ? (
                          quantityMap[title]
                        ) : (
                          <div style={{ position: "relative", left: "42%" }}>
                            <Skeleton variant="circle" width={40} height={40} />
                          </div>
                        )}
                      </div>
                      <div style={{ fontSize: "1rem", color: "#828282" }}>
                        {title}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div
                className="bg-white px-10 pt-10 border rounded mt-10"
                style={{ minHeight: "550px" }}
              >
                <h3 className="mb-8">
                  Quarterly Container Declaration Summary:{" "}
                </h3>
                {loading ? (
                  <Skeleton variant="rect" width={"100%"} height={450} />
                ) : (
                  declarations.map((declaration) => (
                    <Link
                      to={{
                        pathname: `/manufacturers/declaration/${declaration.id}`,
                        declaration: declaration,
                      }}
                      key={declaration.id}
                    >
                      <DeclarationItem
                        item={declaration}
                        key={declaration.id}
                      />
                    </Link>
                  ))
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="mb-10">
                <Calendar />
              </div>
              <div
                className="bg-white p-10 mr-3 border rounded"
                style={{ minHeight: "200px", fontSize: 14 }}
              >
                Should you have declarations that fall outside of the standard
                procedure or require support please contact Agsafe’s drumMUSTER
                team via{" "}
                <span className="font-weight-bold">
                  manufacturers@DrumMuster.org.au
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white p-10 text-center font-weight-bold">
          You don't have enough permissions to view this page!
        </div>
      )}
    </>
  );
};
