/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { DeclarationsList } from "./DeclarationsList";
import { DeclarationCard } from "./DeclarationCard";

export const Declarations = ({ data = [], setData, organisationId }) => {
  const [component, setComponent] = useState("list");
  const [componentId, setComponentId] = useState("");

  const getDeclarationById = () =>
    data && data.find(({ id }) => id === componentId);

  const handleExpand = (id) => () => {
    setComponentId(id);
    setComponent("view");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  const componentsMap = {
    list: (
      <DeclarationsList
        setData={setData}
        data={data}
        handleExpand={handleExpand}
        organisationId={organisationId}
      />
    ),
    view: (
      < DeclarationCard
        data={getDeclarationById()}
        handleClose={handleCancel}
      />
    ),
  };

  return <>{componentsMap[component]}</>;
};
