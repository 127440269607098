export const reviewTypes = {
  SET_DATA: "REVIEW/SET_DATA",
  REMOVE_DATA: "REVIEW/REMOVE_DATA",
  UPDATE_DATA: "REVIEW/UPDATE_DATA",
  UPDATE_INVOICED: "REVIEW/UPDATE_INVOICED",
  ADD_DATA: "REVIEW?ADD_DATA"
};

export function setReviewList(data) {
  return {
    type: reviewTypes.SET_DATA,
    payload: data,
  };
}

export function addReviewItem(data) {
  return {
    type: reviewTypes.ADD_DATA,
    payload: data,
  };
}

export function removeReviewItem(data) {
  return {
    type: reviewTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateReviewList(data) {
  return {
    type: reviewTypes.UPDATE_DATA,
    payload: data,
  };
}


export function updateInvoicedStatus(data) {
  return {
    type: reviewTypes.UPDATE_INVOICED,
    payload: data,
  };
}
