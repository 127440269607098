import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getOrganisationDeclarationsCount,
  getOrganisationHistoryCount,
  getOrganisationReimbursCount,
  getOrganisationTasksCount,
  getSimpleOrganisations,
} from "../../modules/Organisations/_api";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { Tasks } from "./Tasks";
import { OrgReimbursements } from "./OrgReimbursements";
import { OrgSystem } from "./OrgSystem";
import { getContactsSimple } from "../../modules/Contacts/_api";
import { getUsers } from "../../modules/Users/_api";
import { getSitesSimple } from "../../modules/Sites/_api";
import {
  modifyContacts,
  modifyOrganisations,
  modifySites,
  modifyUsers,
} from "../../modules/Tasks/helpers";
import { Skeleton } from "@material-ui/lab";
import { Declarations } from "./Declarations";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";

const countStyle = {
  backgroundColor: "#dfe3e8",
  borderRadius: "8px",
  padding: "1px 12px",
  marginLeft: "6px",
  color: "black",
};

export const History = ({ organisationId, manufacturer }) => {
  const { request } = useFetch();
  const [systemList, setSystemList] = useState([]);
  const [reimbursements, setReimbursements] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksCount, setTasksCount] = useState(0);
  const [siteTaskCount, setSiteTasksCount] = useState(0);
  const [contactTasksCount, setContactTasksCount] = useState(0);
  const [declarations, setDeclarations] = useState([]);
  const [declarationsCount, setDeclarationsCount] = useState(0);

  const [historyCount, setHistoryCount] = useState(0);
  const [reimbursementsCount, setReimbursementsCount] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [organisations, setOrganisations] = useState([]);

  const fetchContacts = () => {
    request(getContactsSimple, "simple=true&fields=id,first_name,last_name")
      .then((data) => data && modifyContacts(data))
      .then((data) => {
        if (!data) return;
        setContacts(data);
      });
  };

  const fetchUsers = () => {
    setUsersLoading(true);
    request(getUsers)
      .then((data) => data && modifyUsers(data))
      .then((data) => {
        if (!data) return;
        setUsers(data);
      })
      .finally(() => setUsersLoading(false));
  };

  const fetchSites = () => {
    request(getSitesSimple, "simple=true&fields=id,name")
      .then((data) => data && modifySites(data))
      .then((data) => {
        if (!data) return;
        setSites(data);
      });
  };

  const fetchOrganisations = () => {
    request(getSimpleOrganisations)
      .then((data) => data && modifyOrganisations(data))
      .then((data) => {
        if (!data) return;
        setOrganisations(data);
      });
  };

  useEffect(() => {
    if (organisationId) {
      Promise.all([
        request(getOrganisationTasksCount, organisationId),
        request(getOrganisationReimbursCount, organisationId),
        request(getOrganisationTasksCount, organisationId, "type=contact"),
        request(getOrganisationTasksCount, organisationId, "type=site"),
        request(getOrganisationDeclarationsCount, organisationId),
        !manufacturer && request(getOrganisationHistoryCount, organisationId),
      ]).then(
        ([
          taskCount,
          reimbursementsCount,
          contactTaskCount,
          siteTaskCount,
          declarationsCount,
          historyCount,
        ]) => {
          taskCount && setTasksCount(taskCount);
          reimbursementsCount && setReimbursementsCount(reimbursementsCount);
          contactTaskCount && setContactTasksCount(contactTaskCount);
          siteTaskCount && setSiteTasksCount(siteTaskCount);
          declarationsCount && setDeclarationsCount(declarationsCount);
          historyCount && setHistoryCount(historyCount);
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchContacts();
    fetchUsers();
    fetchSites();
    fetchOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      label: (
        <span>
          Tasks (Org) <span style={countStyle}>{tasksCount}</span>
        </span>
      ),
      tabPanel: (
        <Tasks
          data={tasks}
          organisationId={organisationId}
          setTasks={setTasks}
          contacts={contacts}
          users={users}
          sites={sites}
          organisations={organisations}
        />
      ),
    },
    {
      label: (
        <span>
          Tasks (Contact) <span style={countStyle}>{contactTasksCount}</span>
        </span>
      ),
      tabPanel: (
        <Tasks
          data={tasks}
          organisationId={organisationId}
          setTasks={setTasks}
          contacts={contacts}
          users={users}
          sites={sites}
          organisations={organisations}
          isContactTasks
        />
      ),
    },
    {
      label: (
        <span>
          Tasks (Site) <span style={countStyle}>{siteTaskCount}</span>
        </span>
      ),
      tabPanel: (
        <Tasks
          data={tasks}
          organisationId={organisationId}
          setTasks={setTasks}
          contacts={contacts}
          users={users}
          sites={sites}
          organisations={organisations}
          isSiteTasks
        />
      ),
    },
    {
      label: (
        <span>
          System <span style={countStyle}>{historyCount}</span>
        </span>
      ),
      tabPanel: (
        <OrgSystem
          data={systemList}
          setData={setSystemList}
          organisationId={organisationId}
        />
      ),
    },
    {
      label: (
        <span>
          Reimbursements <span style={countStyle}>{reimbursementsCount}</span>
        </span>
      ),
      tabPanel: (
        <OrgReimbursements
          organisationId={organisationId}
          data={reimbursements}
          setReimbursements={setReimbursements}
          users={users}
        />
      ),
    },
  ];

  if (usersLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="bg-white rounded py-7 px-10">
      {manufacturer ? (
        <Tabs
          defaultActive="0"
          tabs={[
            ...tabs.slice(0, -1),
            {
              label: (
                <span>
                  Declarations{" "}
                  <span style={countStyle}>{declarationsCount}</span>
                </span>
              ),
              tabPanel: (
                <Declarations
                  data={declarations}
                  setData={setDeclarations}
                  organisationId={organisationId}
                />
              ),
            },
          ]}
          align="left"
          background="white"
          text="rgb(138, 144, 153)"
          selectedTabColor="white"
          selectedColor="#304FFD"
          boxShadow={false}
        />
      ) : (
        <AccessControl
          desiredRoles={[
            AVAILABLE_ROLES.NATIONAL_ADMIN,
            AVAILABLE_ROLES.AGSAFE_ADMIN,
          ]}
          elseContent={
            <Tabs
              defaultActive="0"
              tabs={tabs.slice(0, -1)}
              align="left"
              background="white"
              text="rgb(138, 144, 153)"
              selectedTabColor="white"
              selectedColor="#304FFD"
              boxShadow={false}
            />
          }
        >
          <Tabs
            defaultActive="0"
            tabs={tabs}
            align="left"
            background="white"
            text="rgb(138, 144, 153)"
            selectedTabColor="white"
            selectedColor="#304FFD"
            boxShadow={false}
          />
        </AccessControl>
      )}
    </div>
  );
};
