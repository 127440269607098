/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedMaskedInput } from "@bit/the-glue.frontendcomponents.masked-input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import { OrganisationDetailsSchema } from "../../../helpers/schemas";
import { newOrgStatusOptions, statusOptions } from "./constants";
import { TagsField } from "../../../ui/structures/TagsForm/TagsField";
import { Modal } from "../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../ui/structures/TagsForm/TagsAutocomplete";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addOrganisation,
  getSimpleOrganisations,
  modifyOrganisation,
  abnLookup,
  editOrganisationTags,
  deleteOrganisationTags,
} from "./_api";
import { getTags } from "../Settings/Tags/_api";
import { PostalAddress } from "./PostalAddress";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { TradingNameSelect } from "./TradingNameSelect";
import { uniq } from "lodash";
import { AbnOfflineWarning } from "./AbnOfflineWarning";
import { getRegions, getLGARegionByPostcode } from "../Settings/Regions/_api";
import { getConsultants } from "../Settings/Consultants/_api";
import { modifyEmptyFields, sortHelper } from "../../../ui/helpers";
import { DuplicateWarning } from "./DuplicateWarning";
import { IconButton } from "@material-ui/core";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { Skeleton } from "@material-ui/lab";
import { ListButton } from "../../../ui/components/ListButton";
import { EndUserEdit } from "./EndUserEdit";
import { useSelector } from "react-redux";
import { accessControlFunction } from "../../../ui/structures/AccessControl/AccessControlFunction";
import { Location } from "../../../ui/structures/Location";
import { PhysicalAddress } from "./PhysicalAddress";
import { LGARegionSelect } from "../../../ui/components/LGARegionSelect";
import { MappedCheckbox } from "../../../ui/components/Checkbox";

const options = {
  key: "MN71-JP16-MJ29-NK46",
  search: { countries: "AUS" },
};

export const OrganisationDetails = ({
  data = {},
  setData,
  id,
  name,
  simpleOrganisations = [],
}) => {
  const initialValues = {
    ABN: data.ABN || "",
    legal_name: data.legal_name || "",
    phone: data.phone || "",
    mobile: data.mobile || "",
    email: data.email || "",
    business_name: data.business_name || "",
    status: data.status || "new",
    notes: data.notes || "",
    tags: id ? (data.tags || []).map(({ id }) => id) : [],
    physical_address: data.physical_address || "",
    physical_address2: data.physical_address2 || "",
    physical_city: data.physical_city || "",
    physical_state: data.physical_state || "",
    physical_postcode: data.physical_postcode || "",
    postal_address: data.postal_address || "",
    postal_address2: data.postal_address2 || "",
    postal_city: data.postal_city || "",
    postal_state: data.postal_state || "",
    postal_postcode: data.postal_postcode || "",
    physical_latitude: data.physical_latitude || "",
    physical_longitude: data.physical_longitude || "",
    code: data.code || "",
    region_id: data.region_id || "",
    consultant_id: data.consultant_id || "",
    as_physical: false,
    twitter: data.twitter || "",
    facebook: data.facebook || "",
    lga_region: data.lga_region || "",
    manufacturer: data.manufacturer || false,
    manufacturer_unit_price: data.manufacturer_unit_price || "0.06",
  };

  const history = useHistory();
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const disabledField = !accessControlFunction(
    [
      AVAILABLE_ROLES.NATIONAL_ADMIN,
      AVAILABLE_ROLES.AGSAFE_ADMIN,
      AVAILABLE_ROLES.AGSAFE_STAFF,
      AVAILABLE_ROLES.RC_ACCESS,
    ],
    user
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [regions, setRegions] = useState([]);
  const [lgaRegions, setLgaRegions] = useState([]);
  const [lgaLoading, setLgaLoading] = useState(false);
  const [consultants, setConsultants] = useState([]);
  const [abnLookedUp, setAbnLookedUp] = useState(Boolean(id));
  const [organisationsList, setOrganisationsList] =
    useState(simpleOrganisations);
  const [tradingNameSelect, setTradingNameSelect] = useState(false);
  const [tradingNamesList, setTradingNamesList] = useState([]);
  const [tradingNameValue, setTradingNameValue] = useState("");
  const [tradingNameisInput, setTradingNameisInput] = useState(false);
  const [abnWarningOpen, setAbnWarningOpen] = useState(false);
  const [abnLoading, setAbnloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [submitAction, setSubmitAction] = useState("Save");
  const [tagsLoading, setTagsLoading] = useState(false);
  const [requestChangesOpen, setRequestChangesOpen] = useState(false);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [orgControl, setOrgControl] = useState({});
  const [orgValues, setOrgValues] = useState({});

  const handleMapOpen = () => setMapModalOpen(true);
  const handleMapClose = () => setMapModalOpen(false);

  const handleDuplicateOpen = () => setDuplicateModalOpen(true);
  const handleDuplicateClose = () => setDuplicateModalOpen(false);

  const handleNameSelectClose = () => setTradingNameSelect(false);
  const handleAbnWarningClose = () => setAbnWarningOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleRequestChangesOpen = () => setRequestChangesOpen(true);
  const handleRequestChangesClose = () => setRequestChangesOpen(false);

  const fetchLgaRegions = (values, setFieldValue) => {
    setLgaLoading(true);
    return request(getLGARegionByPostcode, values.physical_postcode)
      .then((data) => {
        if (!data) return;
        setLgaRegions(data.map((region) => ({ value: region, label: region })));
        if (!data.includes(values.lga_region)) setFieldValue("lga_region", "");
      })
      .finally(() => setLgaLoading(false));
  };

  useEffect(() => {
    Promise.all([request(getRegions), request(getConsultants)]).then(
      ([regions, consultants]) => {
        regions &&
          setRegions(
            regions
              .map(({ long_name, id }) => ({ value: id, label: long_name }))
              .sort(sortHelper)
          );
        consultants &&
          setConsultants(
            consultants
              .map(({ first_name, last_name, id }) => ({
                value: id,
                label: [first_name, last_name].join(" "),
              }))
              .sort(sortHelper)
          );
      }
    );
  }, []);

  useEffect(() => {
    setTagsLoading(true);
    request(getTags, "category=_like(Organisation)_")
      .then((tags) => tags && setTags(tags))
      .finally(() => setTagsLoading(false));
  }, []);

  useEffect(() => {
    if (organisationsList.length) return;
    request(getSimpleOrganisations).then(
      (data) => data && setOrganisationsList(data)
    );
  }, []);

  useEffect(() => {
    if (data.ABN) {
      request(abnLookup, data.ABN).then((data) => {
        if (!data) return;
        const filteredNames = uniq(data.trading_names);
        const tradingNamesOptions = [
          ...filteredNames.map((name) => ({
            value: name,
            label: name,
          })),
          { value: data.legal_name, label: data.legal_name },
        ];
        setTradingNamesList(tradingNamesOptions);
        if (
          !tradingNamesOptions.find(({ value }) => value === data.business_name)
        ) {
          setTradingNameisInput(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    const pca = window.pca;
    const siteFields = [
      { element: "org_address", field: "Line2", mode: pca.fieldMode.SEARCH },
      {
        element: "physical_address",
        field: "Line1",
        mode: pca.fieldMode.POPULATE,
      },
      {
        element: "physical_city",
        field: "City",
        mode: pca.fieldMode.POPULATE,
      },
      {
        element: "physical_postcode",
        field: "PostalCode",
        mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
      },
      {
        element: "physical_state",
        field: "Province",
        mode: pca.fieldMode.POPULATE,
      },
    ];
    const siteControl = new window.pca.Address(siteFields, options);
    setOrgControl(siteControl);
    // eslint-disable-next-line
  }, []);

  const handleOrgListen = () => {
    orgControl.listen("populate", function (address, variations) {
      const values = {
        physical_address: address.FormattedLine1,
        physical_city: address.City,
        physical_postcode: address.PostalCode,
        physical_state: address.Province,
      };
      setOrgValues(values);
      handleMapOpen();
    });
  };

  const abnList = organisationsList.map(({ ABN }) => ABN);

  const findDuplicate = (value) => {
    return abnList.find((abn) => abn === value && abn !== data.ABN);
  };

  const createOrganisation = (values, setSubmitting) => {
    setLoading(true);
    request(addOrganisation, values)
      .then((data) => {
        if (!data) {
          setSubmitting(false);
          setLoading(false);
          return;
        }
        if (values.tags.length) {
          return request(
            editOrganisationTags,
            data.id,
            values.tags.map((tagID) => ({ id: tagID }))
          ).finally(() => {
            setSubmitting(false);
            setLoading(false);
          });
        } else return data;
      })
      .then(
        (data) => data && history.push("/organisations/organisations-list")
      );
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editOrganisationTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      );
    } else {
      return request(deleteOrganisationTags, id, data.tags);
    }
  };

  const updateOrganisation = (values, setSubmitting) => {
    setLoading(true);
    Promise.all([
      request(modifyOrganisation, values, id),
      editTagsRequest(values),
    ])
      .then(([organisationData, tags]) => {
        if (!organisationData || !tags) return;
        info("Organisation details have been updated!");
        submitAction === "Save & Exit" &&
          history.push("/organisations/organisations-list");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    modifyEmptyFields(values);
    id
      ? updateOrganisation(values, setSubmitting)
      : createOrganisation(values, setSubmitting);
  };

  const handleBlur = (values, setTouched, setFieldValue) => () => {
    if (findDuplicate(values.ABN)) handleDuplicateOpen();
    if (!values.ABN.includes("_")) {
      setAbnloading(true);
      request(abnLookup, values.ABN)
        .then((data) => {
          if (!data) {
            setAbnLookedUp(false);
          } else if (data === "Server error") {
            setTradingNameisInput(true);
            setAbnWarningOpen(true);
            setAbnLookedUp(true);
          } else {
            setTradingNameisInput(false);
            setAbnLookedUp(true);
            Object.keys(data).map((key) => setFieldValue(key, data[key]));
            const filteredNames = uniq(data.trading_names);
            const tradingNamesOptions = [
              ...filteredNames.map((name) => ({
                value: name,
                label: name,
              })),
              { value: data.legal_name, label: data.legal_name },
            ].sort(sortHelper);
            setTradingNamesList(tradingNamesOptions);
            if (tradingNamesOptions.length === 1) {
              setFieldValue("business_name", tradingNamesOptions[0].value);
            } else {
              setTradingNameSelect(true);
            }
          }
        })
        .finally(() => setAbnloading(false));
    } else {
      setAbnLookedUp(false);
    }
    setTouched({ ABN: true }, true);
  };

  const hanldeSelectTradingName = (setFieldValue) => () => {
    setFieldValue("business_name", (tradingNameValue || {}).value);
    setTradingNameSelect(false);
  };

  const handleClose = () => history.push("/organisations/organisations-list");

  const handleTradingNameSelect = (setFieldValue, setTouched) => {
    setFieldValue("business_name", "");
    setTouched({ business_name: false }, true);
    setTradingNameisInput(false);
  };

  const handleRefreshGST = () => {
    setLoading(true);
    request(modifyOrganisation, { gst_status: true }, id)
      .then((response) => {
        if (!response) return;
        const gstStatus = response.gst_status;
        if (data.gst_status !== gstStatus) {
          setData((state) => ({
            ...state,
            gst_status: gstStatus,
          }));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {<Loader isOpen={abnLoading || loading} maxWidth="xs" />}
      <Modal
        maxWidth="md"
        isOpen={requestChangesOpen}
        submitable
        onClose={handleRequestChangesClose}
        modalContent={
          <EndUserEdit
            handleClose={handleRequestChangesClose}
            setLoading={setLoading}
            data={data}
          />
        }
      />
      <Modal
        maxWidth="sm"
        isOpen={abnWarningOpen}
        submitable
        onClose={handleAbnWarningClose}
        modalContent={
          <AbnOfflineWarning onClose={handleAbnWarningClose} id={id} />
        }
      />
      <Modal
        maxWidth="sm"
        isOpen={duplicateModalOpen}
        onClose={handleDuplicateClose}
        submitable
        modalContent={<DuplicateWarning onClose={handleDuplicateClose} />}
      />
      <div className="border border-secondary bg-white px-10 pb-10 pt-2">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={OrganisationDetailsSchema()}
        >
          {({
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setTouched,
            values,
          }) => (
            <>
              <Modal
                isOpen={mapModalOpen}
                onClose={handleMapClose}
                maxWidth="md"
                submitable
                modalContent={
                  <>
                    <h3>Confirm your location</h3>
                    <Location
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDue0HjL6FN1DXF6ghAaa_HrWLtZWOjyyE"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `85%` }} />}
                      handleClose={handleMapClose}
                    />
                  </>
                }
              />
              <Modal
                maxWidth="md"
                isOpen={modalOpen}
                submitable
                onClose={handleModalClose}
                disableBackdropClick
                modalContent={
                  <TagsAutocomplete
                    name="tags"
                    placeholder="Select Tags"
                    setValue={setFieldValue}
                    options={tags.filter(
                      (item) => !values.tags.includes(item.id)
                    )}
                    loading={!tags.length}
                    onClose={handleModalClose}
                    currentValue={values.tags}
                    multiple
                  />
                }
              />
              <Modal
                maxWidth="sm"
                isOpen={tradingNameSelect}
                submitable
                onClose={handleNameSelectClose}
                modalContent={
                  <TradingNameSelect
                    onClose={handleNameSelectClose}
                    handleSubmit={hanldeSelectTradingName(setFieldValue)}
                    setTradingNameValue={setTradingNameValue}
                    tradingNamesList={tradingNamesList}
                  />
                }
              />

              <div className="d-flex justify-content-between align-items-center">
                <h3>
                  <strong>{id ? name : "Create Organisation"}</strong>
                </h3>
                <div className={`d-flex ${!id && "py-5"}`}>
                  <div className="align-self-center">
                    <ListButton
                      label="Refresh GST status"
                      className="mr-5"
                      onClick={handleRefreshGST}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <strong>
                      <p>
                        {id
                          ? data.abn_verified
                            ? "ABN is verified"
                            : "ABN is not verified"
                          : null}
                      </p>
                    </strong>
                    <strong>
                      <p>
                        {id
                          ? data.gst_status
                            ? "GST is registered"
                            : "GST is not registered"
                          : null}
                      </p>
                    </strong>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-6">
                  <AccessControl
                    desiredRoles={[
                      AVAILABLE_ROLES.NATIONAL_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_STAFF,
                      AVAILABLE_ROLES.RC_ACCESS,
                    ]}
                    elseContent={
                      <MappedInput
                        label="ABN"
                        name="ABN"
                        data-testid="abn"
                        disabled
                      />
                    }
                  >
                    <MappedMaskedInput
                      label="ABN"
                      name="ABN"
                      data-testid="abn"
                      mask="99 999 999 999"
                      onBlur={handleBlur(values, setTouched, setFieldValue)}
                    />
                  </AccessControl>

                  <MappedInput
                    label="Legal Name"
                    name="legal_name"
                    data-testid="legal_name"
                    disabled={!abnLookedUp || disabledField}
                  />
                  <MappedInput
                    label="Phone"
                    name="phone"
                    data-testid="phone"
                    disabled={!abnLookedUp || disabledField}
                  />
                  <MappedInput
                    label="Mobile"
                    name="mobile"
                    data-testid="mobile"
                    disabled={!abnLookedUp || disabledField}
                  />
                  <MappedInput
                    label="Email"
                    name="email"
                    data-testid="email"
                    disabled={!abnLookedUp || disabledField}
                  />
                  <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "95%" }}>
                      {tradingNameisInput ? (
                        <MappedInput
                          label="Business Name"
                          name="business_name"
                          data-testid="business_name"
                          disabled={!abnLookedUp || disabledField}
                        />
                      ) : (
                        <MappedSelect
                          label="Business Name"
                          options={tradingNamesList}
                          name="business_name"
                          data-testid="business_name"
                          disabled={!abnLookedUp || disabledField}
                        />
                      )}
                    </div>
                    <div>
                      {tradingNameisInput ? (
                        <IconButton
                          disabled={!abnLookedUp || disabledField}
                          onClick={() =>
                            handleTradingNameSelect(setFieldValue, setTouched)
                          }
                        >
                          <ListIcon className="cursor-pointer" />
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={!abnLookedUp || disabledField}
                          onClick={() => setTradingNameisInput(true)}
                        >
                          <EditIcon className="cursor-pointer" />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <MappedInput
                    name="twitter"
                    label="Twitter"
                    disabled={disabledField}
                  />
                  {id && (
                    <MappedInput
                      label="Code"
                      name="code"
                      data-testid="code"
                      disabled
                    />
                  )}
                  <div className="d-flex">
                    <MappedCheckbox
                      name="manufacturer"
                      checked={values.manufacturer}
                      label="Manufacturer"
                    />
                    {values.manufacturer && (
                      <MappedInput
                        name="manufacturer_unit_price"
                        label="Unit Price"
                      />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex w-100">
                    <div style={{ width: "47%", marginRight: "15px" }}>
                      <LGARegionSelect
                        values={values}
                        lgaLoading={lgaLoading}
                        lgaRegions={lgaRegions}
                      />
                      <MappedSelect
                        label="Region"
                        options={regions}
                        name="region_id"
                        data-testid="region_id"
                        disabled={disabledField}
                      />
                      <MappedSelect
                        label="Consultant"
                        options={consultants}
                        name="consultant_id"
                        data-testid="consultant_id"
                        disabled={disabledField}
                      />
                      <AccessControl
                        desiredRoles={[
                          AVAILABLE_ROLES.NATIONAL_ADMIN,
                          AVAILABLE_ROLES.AGSAFE_ADMIN,
                          AVAILABLE_ROLES.AGSAFE_STAFF,
                        ]}
                        elseContent={
                          <MappedSelect
                            label="Status"
                            options={id ? statusOptions : newOrgStatusOptions}
                            name="status"
                            data-testid="status"
                            disabled
                          />
                        }
                      >
                        <MappedSelect
                          label="Status"
                          options={id ? statusOptions : newOrgStatusOptions}
                          name="status"
                          data-testid="status"
                          disabled={!abnLookedUp || data.status === "archived"}
                        />
                      </AccessControl>
                    </div>
                    <div className="w-50">
                      {tagsLoading ? (
                        <div className="mb-5">
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={187}
                          />
                        </div>
                      ) : (
                        <TagsField
                          label="Tags"
                          tags={values.tags}
                          handleAdd={handleModalOpen}
                          setValue={setFieldValue}
                          predefinedTags={tags}
                          disabled={disabledField}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <MappedInput
                      label="Notes"
                      name="notes"
                      multiline
                      rows={9}
                      data-testid="notes"
                      disabled={!abnLookedUp || disabledField}
                    />
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <MappedInput
                      name="facebook"
                      label="Facebook"
                      disabled={disabledField}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-10">
                <PhysicalAddress
                  disabledField={disabledField}
                  handleOpen={handleMapOpen}
                  initialValues={initialValues}
                  orgValues={orgValues}
                  handleOrgListen={handleOrgListen}
                  fetchLgaRegions={fetchLgaRegions}
                />
                <PostalAddress id={id} disabledField={disabledField} />
              </div>
              <AccessControl
                desiredRoles={[
                  AVAILABLE_ROLES.NATIONAL_ADMIN,
                  AVAILABLE_ROLES.AGSAFE_ADMIN,
                  AVAILABLE_ROLES.AGSAFE_STAFF,
                  AVAILABLE_ROLES.RC_ACCESS,
                ]}
                elseContent={
                  <div className="text-right">
                    <ListButton
                      label="Request Changes"
                      size="large"
                      onClick={handleRequestChangesOpen}
                    />
                  </div>
                }
              >
                <SubmitActions
                  id={id}
                  handleCancel={handleClose}
                  disabled={isSubmitting || !abnLookedUp}
                  submitAction={submitAction}
                  handleSubmit={handleSubmit}
                  setSubmitAction={setSubmitAction}
                />
              </AccessControl>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
