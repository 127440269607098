/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { Loader } from "../../../../ui/components/Loader";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { useFetch } from "../../../../hooks/fetch.hook";
import { error, info } from "../../../../helpers/toasts";
import {
  removeSummaryItem,
  setSummaryList,
  updateSummaryList,
} from "./_redux/actions";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { HEADINGS, nonSortableManufacturerHeadings } from "./constants";
import {
  modifyDeclarationsSortKey,
  modifySummary,
  modifySummaryArray,
  renderSummaryRow,
} from "./helpers";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import {
  deleteDeclaration,
  getDeclarationById,
  getDeclarations,
  getDeclarationsAmount,
  reactivateDeclaration,
} from "../_api";
import { Skeleton } from "@material-ui/lab";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { getSelectedId, sortHelper } from "../../../../ui/helpers";
import { getManufacturersSimple } from "../../Organisations/_api";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { AccessControl } from "../../../../ui/structures/AccessControl";

export const ManufacturerSummary = () => {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const isManufacturerApprover =
    user.permissions?.is_man_approver || user.permissions?.is_manufacturer;

  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [organisation, setOrganisation] = useState({});
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [unArchiving, setUnarchiving] = useState(false);
  const [selected, setSelected] = useState({});

  const data = useSelector(
    ({ manufacturerSummary: { summaryList } }) => summaryList
  );

  const fetchSummary = () => {
    let searchQuery =
      "status=active&status=awaiting_payment&status=nil_declared&status=draft";
    searchQuery += organisation?.value
      ? `&organisation_id=${organisation?.value}`
      : "";
    searchQuery += archived ? "&status=archived" : "";
    setLoading(true);
    request(
      getDeclarations,
      field,
      field && direction,
      page,
      pageSize,
      searchQuery
    )
      .then((data) => {
        if (!data) return;
        const finalData = modifySummaryArray(data);
        dispatch(setSummaryList(finalData));
      })
      .finally(() => {
        setLoading(false);
        setArchivedLoading(false);
      });
  };

  useEffect(() => {
    if (!isManufacturerApprover) return;
    let searchQuery = `status=active&status=awaiting_payment&status=nil_declared&status=draft`;
    searchQuery += organisation?.value
      ? `&organisation_id=${organisation?.value}`
      : "";
    searchQuery = +archived ? "&status=archived" : "";
    request(getDeclarationsAmount, searchQuery).then(
      (data) => data && setTotalRecords(data)
    );
  }, [organisation, archived]);

  useEffect(() => {
    if (!isManufacturerApprover) return;
    fetchSummary();
  }, [field, direction, organisation, archived, page, pageSize]);

  useEffect(() => {
    if (!isManufacturerApprover) return;
    setOrgsLoading(true);
    request(getManufacturersSimple)
      .then((data) => {
        data && setOrganisations(data);
      })
      .finally(() => setOrgsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const organisationsOptions = useMemo(
    () => [
      ...(organisations || [])
        .map((organiastion) => ({
          value: organiastion.id,
          label: organiastion.business_name,
        }))
        .sort(sortHelper),
    ],
    [organisations]
  );

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    const item = data.find((item) => item.id === String(id));
    if (!item) return;
    if (
      item.status === "nil_declared" ||
      item.status === "submitted" ||
      item.status === "due"
    ) {
      setConfirmDeleteOpen(true);
      setSelectedItem(id);
    } else {
      error("Invoiced items can't be deleted!");
    }
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteDeclaration, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getDeclarationById, id).then(
            (data) => data && dispatch(updateSummaryList(modifySummary(data)))
          );
        } else dispatch(removeSummaryItem(id));
        setSelected({});
        setSelectedItem({});
        info("Item has been archived!");
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      nonSortableManufacturerHeadings.includes(key) ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            const sortKey = modifyDeclarationsSortKey(key);
            setDirection(
              sortKey !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(sortKey);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  const getDefaultValue = () => {
    if (organisation !== null && typeof organisation === "object") {
      return Object.keys(organisation).length ? organisation : null;
    } else return null;
  };

  const handleExpand = (id) => () => {
    const item = data.find((item) => item.id === String(id));
    if (!item) return;
    let url = "";
    if (window.location.hostname === "localhost.dev.agsafe.org.au") {
      url = `https://${window.location.hostname}:${window.location.port}/manufacturers/declaration/${id}`;
    } else {
      url = `https://${window.location.hostname}/manufacturers/declaration/${id}`;
    }
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleUnarchive = () => {
    const declarationID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === declarationID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(reactivateDeclaration, declarationID, {})
      .then((data) => {
        if (!data) return;
        dispatch(updateSummaryList(modifySummary(data)));
        setSelected({});
        info("Item has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  return (
    <>
      {isManufacturerApprover ? (
        <>
          <Loader isOpen={actionLoading} maxWidth="xs" />
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleArchive}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
              />
            }
          />
          <div className="row justify-content-center mt-10">
            <div className="col-12">
              <div className="bg-white rounded py-7 px-10">
                <ListHeader title="Summary" />
                <ArchiveCheckbox
                  archivedLoading={archivedLoading}
                  archived={archived}
                  showArchived={showArchived}
                />
                <AccessControl
                  desiredRoles={[
                    AVAILABLE_ROLES.NATIONAL_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_STAFF,
                  ]}
                >
                  <div className="px-2 col-2" style={{ minWidth: "280px" }}>
                    {orgsLoading ? (
                      <Skeleton variant="rect" width={"100%"} height={40} />
                    ) : (
                      <Autocomplete
                        size="small"
                        placeholder="Select Organisation"
                        defaultValueField="value"
                        value={organisation}
                        defaultValue={getDefaultValue()}
                        onChange={(event, newValue) => {
                          setOrganisation(newValue);
                        }}
                        options={organisationsOptions}
                        sortable={false}
                        getOptionLabel={(option) => option.label || ""}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        inputStyle={{}}
                      />
                    )}
                  </div>
                </AccessControl>
                <div className="d-flex justify-content-end align-items-center mb-10">
                  <AccessControl
                    desiredRoles={[
                      AVAILABLE_ROLES.NATIONAL_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_STAFF,
                    ]}
                  >
                    {archived && (
                      <UnarchiveButton
                        handleClick={handleUnarchive}
                        disabled={unArchiveDisabled}
                        loading={unArchiving}
                      />
                    )}
                  </AccessControl>
                </div>
                <div className="mt-10">
                  <AccessControl
                    desiredRoles={[
                      AVAILABLE_ROLES.NATIONAL_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_ADMIN,
                      AVAILABLE_ROLES.AGSAFE_STAFF,
                    ]}
                    elseContent={
                      <Datagrid
                        data={data}
                        headings={HEADINGS}
                        renderRow={renderSummaryRow}
                        renderHeaderWithSorting={renderHeaderWithSorting}
                        isNewTabLink
                        loading={loading}
                        serverPage={page}
                        setServerPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        isServerPagination
                        totalRecords={totalRecords}
                        expandable
                        handleExpand={handleExpand}
                        expandHeader="Edit"
                        expandIcon={
                          <EditIcon fontSize="small" className="action-icon" />
                        }
                      />
                    }
                  >
                    <Datagrid
                      data={data}
                      headings={HEADINGS}
                      renderRow={renderSummaryRow}
                      renderHeaderWithSorting={renderHeaderWithSorting}
                      isNewTabLink
                      deletable
                      handleDelete={handleOpenModal}
                      loading={loading}
                      selectable
                      selected={selected}
                      setSelected={setSelected}
                      serverPage={page}
                      setServerPage={setPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      isServerPagination
                      totalRecords={totalRecords}
                      expandable
                      handleExpand={handleExpand}
                      expandHeader="Edit"
                      expandIcon={
                        <EditIcon fontSize="small" className="action-icon" />
                      }
                    />
                  </AccessControl>
                  <AccessControl desiredRoles={[AVAILABLE_ROLES.END_USER]}>
                    <div className="mt-10 font-weight-bold text-primary">
                      For declaration history refer to the org profile
                    </div>
                  </AccessControl>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white p-10 text-center font-weight-bold">
          You don't have enough permissions to view this page!
        </div>
      )}
    </>
  );
};
