import { error } from "../../../helpers/toasts";
import { exportDeclarations } from "./_api";

const getDownloadUrl = (data) => {
  if (data.download_url) return data.download_url;
  else if (data.url) return data.url;
  else return "";
};

export const exportDeclarationFiles = (data, setLoading) => {
  return exportDeclarations(data)
    .then((data) => data.json())
    .then((data) => data && startDownload(getDownloadUrl(data.data)))
    .catch((err) => error((err || {}).message))
    .finally(() => setLoading && setLoading(false));
};

const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};
