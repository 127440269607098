export const HEADINGS = [
  ["code", "ID"],
  ["material", "Product Type"],
  ["size", "Container Size L/Kg"],
  ["quantity", "Container Qty"],
  ["unit_price", "Price Per Unit"],
  ["total_due", "Total Levy Due"],
  ["percentage_by_state", "Percentage by State"],
];

export const productTypeOptions = [
  { value: "steel", label: "Steel" },
  { value: "plastic", label: "Plastic" },
];

export const yearOptions = [
  { value: "2018-2019", label: "2018-2019" },
  { value: "2019-2020", label: "2019-2020" },
  { value: "2020-2021", label: "2020-2021" },
  { value: "2021-2022", label: "2021-2022" },
  { value: "2022-2023", label: "2022-2023" },
  { value: "2023-2024", label: "2023-2024" },
];

export const quarterOptions = [
  { value: "1", label: "Q1 Jul-Sep" },
  { value: "2", label: "Q2 Oct-Dec" },
  { value: "3", label: "Q3 Jan-Mar" },
  { value: "4", label: "Q4 Apr-Jun" },
];

export const editableStatusesAdmin = ["due", "submitted", "nil_declared"];

export const editableStatusesUser = ["due", "nil_declared"];
