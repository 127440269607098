export const HEADINGS = [
  ["id", "User ID"],
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["phone_number", "Mobile"],
  ["email", "Email Address"],
  ["status", "Status"],
  ["region", "Region"],
  ["consultant", "Consultant"]
];

export const ORG_SEARCH_HELPER = [
  ["code", "Org ID"],
  ["business_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["status", "Status"]
];

export const orgSearchMap = {
  "Org ID": "code",
  "Business Name": "business_name",
  "Email Address": "email",
  "Phone Number": "phone",
  Status: "status"
};

export const orgHeadings = [
  ["code, Org ID"],
  ["business_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["status", "Status"]
];

export const searchMap = {
  Name: "first_name"
};

export const ACTIVITY_HEADINGS = [
  ["url", "URL"],
  ["created_at", "Date"],
  ["status_code", "Status"]
];
